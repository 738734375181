
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const prodConfig = {
    //why? because, we want cognito oauth tokens to be available in all tbd.health domains and allow common login
    "cookieStorage": {
        // - Cookie domain (only required if cookieStorage is provided)
        "domain": '.tbd.health'
    },
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": 'us-east-1:c5a6cf75-1cd1-481f-8a48-e99c9cf32065',
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_bKETNr2yc",
    "aws_user_pools_web_client_id": '265lp6g73s91i52oj70jugd03e',
    "authenticationFlowType": "USER_PASSWORD_AUTH"
};

const sandboxConfig = {
    //why? because, we want cognito oauth tokens to be available in all tbd.health domains and allow common login
    "cookieStorage": {
        // - Cookie domain (only required if cookieStorage is provided)
        "domain": '.tbdhealth.xyz'
    },
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": 'us-east-1:9e9b1b40-aae6-410f-b9b3-3859fafa8b6f',
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_uN4sTX0KR",
    "aws_user_pools_web_client_id": '1oee82ut73p0dgjpj86jcadu0o',
    "authenticationFlowType": "USER_PASSWORD_AUTH"
};

const localConfig = {
    //why? because, we want cognito oauth tokens to be available in all tbd.health domains and allow common login
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": 'us-east-1:9e9b1b40-aae6-410f-b9b3-3859fafa8b6f',
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_uN4sTX0KR",
    "aws_user_pools_web_client_id": '1oee82ut73p0dgjpj86jcadu0o',
    "authenticationFlowType": "USER_PASSWORD_AUTH"
};

const config = process.env.REACT_APP_ENV.toLowerCase() === 'production'
    ? prodConfig
    : process.env.REACT_APP_ENV.toLowerCase() === 'development' ? localConfig : sandboxConfig;

export default config;
