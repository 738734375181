import React, { useState, useRef, useEffect } from "react";
import "./PdfDisplay.css";
import { Modal } from "react-bootstrap";
import {FaSpinner} from "react-icons/fa";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { isDesktop, isMobile } from "react-device-detect";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { GrSecure } from "react-icons/gr";
import { RiDownload2Line } from "react-icons/ri";
import { GrFormNext } from "react-icons/gr";
import { GrFormPrevious } from "react-icons/gr";
import ApiService from "../../ApiService";

const PdfDisplay = ({
  validatePwdCb,
  fileDisplayName,
  viewFileUrl,
  viewFileUrlHmac,
  downloadFileUrl,
  downloadFileUrlHmac,
  handleClosePdf
}) => {
  const [errMsg, setErrMsg] = useState(null);
  const [password, setPassword] = useState(null);
  const [isShowPwdCollectionModal, setIsShowPwdCollectionModal] = useState(
    true
  );
  const [isShowPdfViewerModal, setIsShowPdfViewerModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const errMsgRef = useRef(null);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const showPdfViewerModal = () => {
    setIsShowPwdCollectionModal(false);
    setIsShowPdfViewerModal(true);
  };

  useEffect(() => {
    setErrMsg(null);
  }, []);

  useEffect(() => {
    if (errMsgRef.current) {
      errMsgRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start"
      });
    }
  }, [errMsg]);

  const handlePwdSubmit = async () => {
    setErrMsg(null);
    const isPass = await validatePwdCb(password).then(r => r);
    if (isPass === true || isPass === "true") {
      showPdfViewerModal();
      return;
    } else {
      setErrMsg("Incorrect password, please retry with correct value");
    }
  };

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { GoToNextPage, GoToPreviousPage } = pageNavigationPluginInstance;

  const handleAskPassword = e => {
    e.verifyPassword(password);
  };

  const handleClose = () => {
    setPassword(null);
    setErrMsg(null);
    handleClosePdf();
  };

  function getViewResultFileHttpHeaders() {
    return {
      Authorization: `Bearer ${localStorage.getItem("loggedInToken")}`,
      addresseeType: "P",
      "content-type": "application/json",
      "x-api-key": viewFileUrlHmac,
      "X-File-Pwd-Signature": ApiService.__getHmacFor(password)
    };
  }

  async function handleDownloadClick() {
    if(isDownloading === true) return;
    setErrMsg(null);
    if (isDesktop) {
      await downloadFileWithHeadersDesktop()
          .then(res => res)
          .finally(() => setIsDownloading(false));
    } else {
      await downloadFileWithHeadersMobile()
          .then(res => res)
          .finally(() => setIsDownloading(false));
    }
  }

  async function downloadFileWithHeadersMobile() {
    setIsDownloading(true);
    try {
      const headers = new Headers();
      headers.append(
        "Authorization",
        `Bearer ${localStorage.getItem("loggedInToken")}`
      );
      headers.append("addresseeType", "P");
      headers.append("content-type", "application/json");
      headers.append("x-api-key", downloadFileUrlHmac);
      headers.append("X-File-Pwd-Signature", ApiService.__getHmacFor(password));

      const response = await fetch(downloadFileUrl, {
        headers: headers
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Open the URL in a new window
      if (navigator.userAgent.match(/safari/i)) {
        window.location.href = url;
      } else {
        // For other browsers, open in a new tab
        window.open(url, "_blank");
      }

      // Clean up the URL and revoke object URL
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    } catch (error) {
      // Handle errors
      setErrMsg(
        "Error downloading file. Please try again. If the issue persists, please email hello@tbd.health"
      );
      console.error("Error downloading file:", error);
    } finally {
      setIsDownloading(false);
    }
  }

  async function downloadFileWithHeadersDesktop() {
    setIsDownloading(true);
    try {
      const headers = new Headers();
      headers.append(
        "Authorization",
        `Bearer ${localStorage.getItem("loggedInToken")}`
      );
      headers.append("addresseeType", "P");
      headers.append("content-type", "application/json");
      headers.append("x-api-key", downloadFileUrlHmac);
      headers.append("X-File-Pwd-Signature", ApiService.__getHmacFor(password));

      const response = await fetch(downloadFileUrl, {
        headers: headers
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileDisplayName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      // Handle errors
      setErrMsg(
        "Error downloading file. Please try again. If issue persists, please email hello@tbd.health"
      );
      console.error("Error downloading file:", error);
    }finally {
      setIsDownloading(false);
    }
  }

  return (
    <Modal
      show={true}
      onHide={handleClose}
      keyboard={false}
      onClick={e => e.stopPropagation()}
      animation={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="pdfModalBackdrop"
      centered
    >
      <Modal.Body
        className={`${isShowPdfViewerModal ? "pdfViewer-Modal" : null}`}
        //style={{height: isShowPdfViewerModal ? "80%" : "60%"}}
      >
        <div className="pdfModal-Container " >
          {isShowPwdCollectionModal && (
            <div className="section1">
              <span className="close-x" onClick={handleClose}>
                X
              </span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px"
                }}
              >
                <span>
                  <GrSecure className="iconWhite" />
                  <div className="bold">
                    For your security, the results are encrypted.
                    <br />
                    Please enter your date of birth in the format MMDDYY
                  </div>
                  <div className="example">
                    For example, if your birthdate is May 11, 1987, enter
                    051187.
                  </div>
                </span>
              </span>
              <span></span>
              <div className="section1-input">
                {/* <input
                    type="password"
                    className="pdfInput"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  /> */}
                <div className="pdfRow">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="pdfInput"
                    onChange={e => {
                      setPassword(e.target.value);
                    }}
                  />
                  <span
                    className="pdfInputIcon"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible />
                    ) : (
                      <AiOutlineEye />
                    )}
                  </span>
                </div>
                <button
                  className="pdf-proceed proceedButton"
                  onClick={handlePwdSubmit}
                >
                  Proceed
                </button>
              </div>
              {errMsg && <b className="errMsg">{errMsg}</b>}
            </div>
          )}

          {isShowPdfViewerModal && (
            <>
              <span className="close-x-2" onClick={handleClose}>
                X
              </span>

              {/*<button className="pdf-proceed proceedButton noMargin" onClick={handleDownloadClick}>*/}
              {/*      /!* <a className="download-link"   download> *!/*/}
              {/*        Secured Download&nbsp;&nbsp;<RiDownload2Line />*/}
              {/*      /!* </a> *!/*/}
              {/*    </button>*/}
              <div
                className="pdf-header"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flex: "1",
                  padding: "15px"
                }}
              >
                <span>
                  <div
                    className="bold"
                    style={{
                      fontSize: "1.8rem"
                    }}
                  >
                    <GrSecure className="iconWhite" /> Please enter your date of
                    birth in MMDDYY format to open the downloaded file.
                  </div>
                  <div className="example">
                    For example, if your birthdate is May 11, 1987, enter
                    051187.
                  </div>
                </span>
              </div>
              <div>
                <div className="pdf-viewer-container">
                  <div className="pdf-viewer">
                    <Viewer
                      httpHeaders={getViewResultFileHttpHeaders()}
                      fileUrl={viewFileUrl}
                      plugins={[pageNavigationPluginInstance]}
                      onDocumentAskPassword={handleAskPassword}
                    />
                  </div>
                  <div
                    style={{
                      alignItems: "center",
                      backgroundColor: "#eee",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                      display: "flex",
                      justifyContent: "center",
                      padding: "4px"
                    }}
                  >
                    <div style={{ padding: "5px 10px" }}>
                      <GoToPreviousPage>
                        {props => (
                          <button
                            className="pdf-nav-button"
                            style={{
                              backgroundColor: props.isDisabled
                                ? "rgba(238,129,85,0.5)"
                                : "#EE8155",
                              border: "none",
                              color: "black",
                              borderRadius: "10vw",
                              cursor: props.isDisabled
                                ? "not-allowed"
                                : "pointer",
                              fontSize: "1.3rem",
                              padding: "2px 10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                            disabled={props.isDisabled}
                            onClick={props.onClick}
                          >
                            <GrFormPrevious style={{}} />
                          </button>
                        )}
                      </GoToPreviousPage>
                    </div>
                    <div style={{ padding: "5px 10px" }}>
                      <GoToNextPage>
                        {props => (
                          <button
                            className="pdf-nav-button"
                            style={{
                              backgroundColor: props.isDisabled
                                ? "rgba(238,129,85,0.5)"
                                : "#EE8155",
                              border: "none",
                              borderRadius: "10vw",
                              fontSize: "1.3rem",
                              color: "black",
                              cursor: props.isDisabled
                                ? "not-allowed"
                                : "pointer",
                              padding: "2px 10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                            disabled={props.isDisabled}
                            onClick={props.onClick}
                          >
                            <GrFormNext style={{}} />
                          </button>
                        )}
                      </GoToNextPage>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                    className="pdf-proceed proceedButton"
                    onClick={handleDownloadClick}
                    disabled={isDownloading}
                    style={{ backgroundColor: isDownloading ? "#F6C0AC" : "#ee8155",
                      marginTop: "20px" }}
                >
                  {isDownloading ? (
                      <>
                        <FaSpinner className="spinner" />&nbsp;Downloading...
                      </>
                  ) : (
                      <>
                        Download&nbsp;&nbsp;
                        <RiDownload2Line />
                      </>
                  )}
                </button>
              </div>
              {errMsg && (
                <div className="errMsg" ref={errMsgRef}>
                  {errMsg}
                </div>
              )}
              <br />
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PdfDisplay;
