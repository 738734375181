import React, { useState, useEffect } from "react";
import ApiService from "../../ApiService";
import Auth from "@aws-amplify/auth";
import MaterialTable from 'material-table';
import RemoveCSS from "../../util/RemoveCSS";
import GetAppIcon from '@material-ui/icons/GetApp';
import ReplyIcon from '@material-ui/icons/Reply';
import ShareModal from './ShareModal';
import RecipientsModal from './RecipientsModal';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import './style.css';
import MTLDownloadPopup from "./MTLDownloadPopup";
import PdfDisplay from "../../components/Modals/PdfDisplay";
import CheckDeviceWidth from "../../util/checkDeviceWidth";
import { FaShare } from 'react-icons/fa';
import {useLocation} from "react-router-dom";
import {getBackendBaseUrl} from "../../config/config";


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const viewResultBaseUrl =  '/secure/userhistory/v2/viewTestResult';
const downloadResultBaseUrl = '/secure/userhistory/v2/download';

const History = () => {

    const [customerIdState, setCustomerIdState] = useState(null);
    const [historyArr, setHistoryArr] = useState([]);
    const [showRecipientModal, setShowRecipientModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const [shareData, setShareData] = useState([]);
    const [shareKitId, setShareKitId] = useState(null);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackText, setSnackText] = useState('');
    const [dataTableMessage, setTableMessage] = useState('Loading Data...');

    const [showDownloadRequestPopup, setShowDownloadRequestPopup] = useState(false);
    const [selectedHistory, setSelectedHistory] = useState(null);

    const [viewPdf, setViewPdf] = useState(false);
    const [currResFileName, setCurrResFileName] = useState(null);
    const [currResKitId, setCurrResKitId] = useState(null);
    const [currViewResultUrl, setCurrViewResultUrl] = useState(null);
    const [currDownloadResultUrl, setCurrDownloadResultUrl] = useState(null);

    const location = useLocation();
    const kitIdUrlParamVal = new URLSearchParams(location.search).get('kitId');
    const actionUrlParamVal = new URLSearchParams(location.search).get('action');

    const width = CheckDeviceWidth();

    const handleSnackClose = () => {
        setSnackOpen(false);
    };

    const handlesetShowRecipientModalClose = () => setShowRecipientModal(false);
    const handleSetShowShareModalClose = () => setShowShareModal(false);

    RemoveCSS();
    document.body.classList.add("body_history");

    useEffect(() => {
        Auth.currentAuthenticatedUser()
        .then(user => {
            Auth.currentSession().then(sess => {
                setCustomerIdState(sess.getIdToken().getJwtToken());
            })
        })

        async function getUserHistory() {
            try {
                const getUserHistory = await ApiService.getUserHistory();
                setHistoryArr(getUserHistory.data);
                if(!getUserHistory?.data.length){
                    setTableMessage('No records found!!!');
                }
            } catch(ex) {
                console.error(ex);
            }
        }
        getUserHistory();
    }, []);

    useEffect(() => {
        if(!historyArr?.length || actionUrlParamVal !== 'access_report' || !kitIdUrlParamVal?.length) return;
        const matchHistoryRow = historyArr.find(h => h.kitId === kitIdUrlParamVal);
        if(!matchHistoryRow) return;
        handleViewOrDownloadResult(matchHistoryRow);
    },[historyArr]);

    const openShareModal = (kitId) => {
        async function getUserSharing() {
            try {
                const getUserSharing = await ApiService.getUserSharing(kitId);
                setShareData(getUserSharing.data)
            } catch(ex) {
                console.error(ex);
            }
            setShareKitId(kitId)
            setShowShareModal(true)
        }
        getUserSharing();
    }

    const openRecipientsModal = (kitId) => {
        setShareKitId(kitId)
        setShowRecipientModal(true)
    }

    const handleCloseDownloadPopup = () => {
        setShowDownloadRequestPopup(false);
        setSelectedHistory(null);
    }
    
    const handleViewOrDownloadResult = async (rowData) => {
        setSelectedHistory(rowData);
        if (!rowData?.hasDownloadableLabResults) {
            if (rowData?.showDownloadReqReceived) {
                setShowDownloadRequestPopup(true);
                await ApiService.sendRequestToDownloadResultFile(rowData.kitId);
            }
            return;
        }
        const resFileName = rowData.labResultNames[0];
        const kitId = rowData.kitId;
        setCurrResKitId(kitId);
        setCurrResFileName(resFileName);
        setCurrViewResultUrl(`${viewResultBaseUrl}/${kitId}/${resFileName}`);
        setCurrDownloadResultUrl(`${downloadResultBaseUrl}/${kitId}/${resFileName}`);
        setViewPdf(true);
    }
    const handleClosePdf = () => {
        setCurrResKitId(null);
        setCurrResFileName(null);
        setViewPdf(false);
    }

    const validatePwd = async (pwd) => {
        async function validatePwdCaller() {
            try {
                const isPass = await ApiService.validatePwdFor(currResKitId, currResFileName, pwd)
                    .then(res => true)
                    .catch(err => false);
                return isPass;
            } catch (err) {
                return false;
            }
        };
        const res = await validatePwdCaller().then(r => r);

        return res;
    }

    const getHttpHeaders = (url) => {
        return {
            "authorization": `Bearer ${localStorage.getItem("loggedInToken")}`,
            "addresseeType": "P",
            "content-type": "application/json",
            "x-api-key": ApiService.__getHmacFor(url),
        }
    }

    return (
        
        <>
        {viewPdf && <PdfDisplay
            fileDisplayName={currResFileName}
            validatePwdCb={validatePwd}
            viewFileUrl={`${getBackendBaseUrl()}${currViewResultUrl}`}
            downloadFileUrl={`${getBackendBaseUrl()}${currDownloadResultUrl}`}
            downloadFileUrlHmac={ApiService.__getHmacFor(currDownloadResultUrl)}
            viewFileUrlHmac={ApiService.__getHmacFor(currViewResultUrl)}
            handleClosePdf={handleClosePdf} />
        }

        <div className="msg_panel panel-default">
            <div className="inbox_header">
                <h1 className="inbox_header header_font">History</h1>
            </div>
            <div className="history-table">
                <MaterialTable
                    columns={[
                        { title: 'Test', field: 'productName' },
                        { title: 'Screening Date', field: 'createDateTime', render: rowData => (
                            moment(rowData.createDateTime).format("LLLL")
                        ),
                        }
                    ]}
                    data={historyArr}
                    title="Patient History"
                    actions={[
                        {
                            icon: () => (
                                <button className="pdf-proceed">
                                    <GetAppIcon style={{ fontSize: 20 }} />&nbsp;View
                                </button>
                            ),
                            tooltip: 'View/Download',
                            onClick: (event, rowData) => handleViewOrDownloadResult(rowData)
                        },
                        {
                            icon: () => (<FaShare style={{ fontSize: 20 }} >&nbsp;Share</FaShare>),
                            tooltip: 'Share',
                            onClick: (event, rowData) => openShareModal(rowData.kitId)
                        },
                        {
                            icon: 'group',
                            tooltip: 'See Recepients',
                            onClick: (event, rowData) => openRecipientsModal(rowData.kitId)
                        }
                    ]}
                    options={{
                        filtering: false,
                        headerStyle: { position: 'sticky', top: 0 },
                        maxBodyHeight: '430px',
                        search: false,
                        pageSize: 50,
                        pageSizeOptions : [50, 100, 200],
                        emptyRowsWhenPaging: false,
                        actionsColumnIndex: width >= 1024 ? -1 : 1,
                        draggable: false,
                        showTitle: false,
                    }}
                    localization={{
                        body: {
                            emptyDataSourceMessage: dataTableMessage
                        }
                    }}
                />
            </div>
            <ShareModal handleClose={handleSetShowShareModalClose} show={showShareModal} shareData={shareData} customerIdState={customerIdState} kitId={shareKitId}/>
            <RecipientsModal kitId={shareKitId} handleClose={handlesetShowRecipientModalClose} show={showRecipientModal} customerIdState={customerIdState} />
            <Snackbar open={snackOpen} onClose={handleSnackClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackClose} severity="success">
                    Please check your email for the passcode to {snackText} your results. The email will come from do-not-reply@tbd.health. Please check your spam folder.
                </Alert>
            </Snackbar>
            <MTLDownloadPopup show={showDownloadRequestPopup}
                              history={selectedHistory}
                              handleClose={handleCloseDownloadPopup} />
        </div>
        </>
    )
};
export default History;
