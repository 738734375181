import React from "react";
import { Modal, Button } from "react-bootstrap";

const PassMemberProfilePicModal = props => {
    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            keyboard={false}
            onClick={e => e.stopPropagation()}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title className="profile-pic-modal-header">Upload profile pic</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                You'll need to upload profile picture before proceeding.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.goTo}>Go to settings</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default PassMemberProfilePicModal;
