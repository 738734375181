import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import url from "../../util/routeURL";

const CountDown = () => {
    const [component, setComponent] = useState(null);
    const history = useHistory();

    const homeButton = () => {
        history.push(url.home);
    };

    useEffect(() => {
        if (window.location.href.indexOf("confirm") > -1) {
            setComponent(
                <div className="countdown-content">
                    <i className="fas fa-spinner fa-pulse"></i>
                    You will be redirected to the Login page shortly...
                </div>
            );
        } else if (window.location.href.indexOf("registerkit") > -1) {
            setComponent(
                <div className="countdown-content countdown-flex">
                    <br/>
                    Kit Registration Successful! 
                    <button type="button" onClick={homeButton} className="btn socialbutton-customizable" id="socialbutton-customizable1">
                        Back to Dashboard
                    </button>
                    </div>
            );
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.href]);

    return (
        <div className="modal-container" >
            {component}
        </div>
    )
};

export default CountDown;
