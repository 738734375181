import React, { useState, useRef } from "react";
import {OverlayTrigger, Button,  Tooltip} from "react-bootstrap";

const UploadImagePopOver = () => {
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          We ask for a profile photo to assist with ID verification
        </Tooltip>
      );
      
      return(
        <span onClick={(e) => e.stopPropagation()}>
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 200 }}
            overlay={renderTooltip}
            trigger={['click', 'hover']}            
          >
            <Button className="infobox">?</Button>
          </OverlayTrigger>
        </span>
      )
}

export default UploadImagePopOver;