const ConfirmChange = (props) => {
    const doProceed = () => {
        props.proceed();
    }

    const doCancel = () => {
        props.cancel();
    }

    return (
        <div className="popupArea">
            <div className="popupTextArea">
                <button className="closeBtn">
                    <img src=".../../../public/images/Cross.png" alt=""/>
                </button>
                <div className="titlePopup">Unsaved details</div>
                <p>You are attempting to leave this page without
                    saving your changes </p>
                <button className="leaveBtn" onClick={doProceed}>Leave without saving</button>
                <button className="cancelBtn" onClick={doCancel}>Cancel</button>
            </div>
        </div>
    )
}
export default ConfirmChange;
