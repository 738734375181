import React, { useState, useEffect } from "react";
import './style.css';
import { Modal, Button } from "react-bootstrap";

const MTLDownloadPopup = (props) => {

    const isMirimusKit = () => {
        return props.history && props.history.kitId && props.history.kitId.startsWith('M19');
    }
    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            keyboard={false}
            onClick={e => e.stopPropagation()}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                {
                    isMirimusKit() ?
                    <span>Your request to download your lab results has been received. “Your request to download your lab results has been received. If you received a COVID-19 test your results would be emailed to you directly. Please email hello@tbd.health if you have any questions.</span>
                    :
                    <span>Your request to download your lab results has been received. Someone from TBD Health will be emailing you your results shortly.</span>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.handleClose}>Ok</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MTLDownloadPopup;