import React from 'react'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const PassMemberTermsModal = props => {
  const [disableBtn, setDisableStatus] = useState(true)
  const [enabledOnce, setEnabledOnceStatus] = useState(false)
  const scrollHandler = event => {
    const containerHeight = event.currentTarget.clientHeight
    const scrollHeight = event.currentTarget.scrollHeight
    const scrollTop = event.currentTarget.scrollTop
    const scrollScore = ((scrollTop + containerHeight) / scrollHeight) * 100
    if (!enabledOnce && Math.ceil(scrollScore) === 100) {
      setDisableStatus(false)
      setEnabledOnceStatus(true)
    }
  }
  return (
      <Modal
          show={props.show}
          backdrop="static"
          keyboard={false}
          onClick={e => e.stopPropagation()}
          animation={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={e => props.handleClose(false)}
      >
        <Modal.Header style={{ background: '#006147', border: 'none' }}>
          <Modal.Title class="terms-modal-header">PASS Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: '#006147', border: 'none' }}>
          <div class="terms-content" onScroll={scrollHandler}>
            This is an authorization (“Authorization”) for you to send your Protected Health Information, as defined under law (“PHI”) electronically to the Performer
            Availability Screening Service, Inc., a California not for profit corporation (“PASS”), on behalf of itself and its program: PASS Certified Program.
            <p />
            By clicking “AUTHORIZE”, you acknowledge and authorize that you are electronically sharing your TBD Health Inc. (“TBD”) test results with PASS and its Database
            and have read and agree to this Authorization.
            <p />
            Questions? Email us at hello@tbd.health
            <p />
            <Modal.Header>
              <Modal.Title class="terms-modal-header">AUTHORIZATION TO ELECTRONICALLY SEND RESULTS TO THE PASS CERTIFIED DATABASE</Modal.Title>
            </Modal.Header>
            <p />
            I hereby authorize TBD HEALTH INC., a Delaware corporation, Inc. (the “Company”) to electronically send my laboratory test results and any such other
            corresponding information or data (“Results”) to the PASS Certified Database. This Authorization shall be in effect as of the date hereof and until I revoke it
            as set forth below.
            <p />
            I acknowledge that all Results shall be provided in the format as is required under PASS and may or may be considered "protected health information" under the
            Health Insurance Portability and Accountability Act (“HIPAA”). Furthermore, the Results pursuant to this Authorization may be subject to re-disclosure or
            distribution by the party(ies) controlling or using the Database (“Third Parties”), and to the extent it has been disclosed to such party, your PHI may no
            longer be protected by the privacy and security regulations of HIPAA or otherwise.
            <p />
            I acknowledge that the Company is not affiliated with such Third Parties and is not responsible for or otherwise endorse any actions whatsoever of these Third
            Parties. Please contact PASS at info@passcertified.org should you have additional questions or want to review its Privacy Policy or other legal documentation
            associated with the Database. This Authorization shall not give rise to any third party beneficiary besides you, the performer, and the Company.
            <p />
            I acknowledge that the Company explicitly disclaims liability for any and all losses and damages in accordance with the Terms of Service (incorporated by
            reference herein), and which shall include but not be limited to the incorrect entry of data, such as recipient name, email and/or phone, or if a technological
            or any other malfunction shall occur.
            <p />
            It is further acknowledged that the Company can only send Results based on the information provided by you to the Company, and if such information is missing,
            or not accurate, then the Results will not be sent to or accurately located in the Database. State law requirements may also limit the electronic transmission
            of certain test results.
            <p />I understand that this Authorization will remain in effect for all subsequent testing, as the case may be, that I may receive at the Company, and such
            Authorization shall expire when I revoke access to such Results or when I otherwise notify the Company in writing by emailing hello@tbd.health. Such revocation
            requests shall be effective ten (10) days after submitted to the Company.
          </div>
          <img src={'/img/overlay.png'} class="terms-content-blur" />
        </Modal.Body>
        <Modal.Footer style={{ background: '#006147', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button
              variant="light"
              size="lg"
              style={{ width: '50%', background: '#EE614D', borderRadius: "500px", fontWeight: '600', marginBottom: 18 }}
              disabled={disableBtn}
              onClick={e => props.handleClose(true)}
          >
            AUTHORIZE
          </Button>
          <Button
              variant="outline-light"
              size="sm"
              style={{ width: '50%', background: 'transparent', borderRadius: "500px", fontWeight: '600', border: '1px solid #ffffff' }}
              disabled={disableBtn}
              onClick={e => props.handleClose(false)}
          >
            DO NOT AUTHORIZE
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default PassMemberTermsModal;