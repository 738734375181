import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Switch, withRouter, Redirect} from "react-router-dom";
import RegisterKit from "./pages/registerkit";
import TbdHealthHeader from "./pages/navigation";
import Inbox from "./pages/messages/MessageIndexInbox";
import SignUpForm from "./pages/signup";
import LoginForm from "./pages/login";
import AuthRoute from './util/AuthRoute';
import ProtectedRoute from './util/ProtectedRoute';
import url from './util/routeURL';
import Dashboard from "./pages/dashboard";
import Outbox from "./pages/messages/MessageIndexOutbox";
import ForgotPassword from "./pages/login/ForgotPassword";
import ConfirmForm from "./pages/login/LoginConfirmation";
import UserSettings from "./pages/UserSettings";
import History from "./pages/history";
import Landing from "./pages/landing";
import LandingNonPass from "./pages/landing/LandingNonPass";
import GoogleAnalytics from './GoogleAnalytics';
import LockedRoute from "./util/LockedRoutes";
import HealthProfile from "./pages/healthProfile";
import ManageSubscription from "./pages/manageSubscription";
import CustomRedirect from "./components/common/CustomRedirect";

const Navigation = (props) => {

    return (
        <Router>
            <Switch>
                <TbdHealthHeader loginStatus={props.loginStatus}/>
            </Switch>
            <Switch>
                <Route
                    path={["/signup", "/sign-up"]}
                    exact
                    render={() => (
                        <CustomRedirect
                            redirectUrl={`${process.env.REACT_APP_MAIN_SITE_BASE_URL}/sign-up?redirect=patient-portal`}
                            isNewWindow={false}
                        />
                    )}
                />
                <Route
                    path={["/login", "/sign-in", "/signin"]}
                    exact
                    render={() => (
                        <CustomRedirect
                            redirectUrl={`${process.env.REACT_APP_MAIN_SITE_BASE_URL}/sign-in?redirect=patient-portal`}
                            isNewWindow={false}
                        />
                    )}
                />
                <AuthRoute exact path={url.registerkit} component={RegisterKit}/>
                <AuthRoute exact path={url.home} component={Dashboard}/>
                <AuthRoute exact path={url.inbox} component={Inbox}/>
                <AuthRoute exact path={url.sent} component={Outbox}/>
                <AuthRoute exact path={url.history} component={History}/>
                <AuthRoute exact path={url.setting} component={UserSettings}/>
                <AuthRoute exact path={url.history} component={History}/>
                <AuthRoute exact path={url.healthProfile} component={HealthProfile} {...props} />
                <ProtectedRoute path={url.forgotPword} component={ForgotPassword}/>
                <ProtectedRoute path={url.confirm} component={ConfirmForm}/>
                <AuthRoute path={url.manageSubscription} component={ManageSubscription}/>
                <Landing path={url.landing}/>
                <LandingNonPass path={url.nonpass} />
            </Switch>
            <GoogleAnalytics/>
        </Router>
    )
};

export default Navigation;
