import React from "react";
import {get, isEqual} from "lodash";
import * as yup from 'yup';
import {Input} from "reactstrap";
import {Formik} from 'formik';
import {getStates, validPhoneNumber, validZipCode} from "../../config/config";
import SaveConfirmation from "./SaveConfirmation";
import EditRoundedIcon from '@material-ui/icons/EditRounded';

const MsgSaveAddress = 'Address are updated successfully'

class AddressBook extends React.Component {
    initstate = null
    states = null
    schema = yup.object().shape({
            street_address: yup.string()
                .required('Street Address is requred'),
            locality: yup.string()
                .required('City is requred'),
        phoneNumber: yup.string()
                .required('Phone is requred')
                .test('valid-phone', 'Invalid phone number', (phoneNumber => {
                    return validPhoneNumber(phoneNumber)
                })),
            postal_code: yup.string()
                .required('Zipcode is requred')
                .test('valid-zip', 'Invalid Zipcode', (postal_code, {parent}) => {
                    const {region} = parent
                    return validZipCode(region, postal_code)
                })
        }
    );

    constructor(props) {
        super(props)
        const {firstName, lastName, address, phoneNumber} = props.details
        this.initstate = {firstName, lastName, phoneNumber, ...address}
        this.state = {...this.initstate, view: true, showNotification: false}
        this.renderView = this.renderView.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleOnEdit = this.handleOnEdit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.renderNotification = this.renderNotification.bind(this)
        this.handleNotificationClose = this.handleNotificationClose.bind(this)
        this.states = getStates()
    }

    handleChange(formchange) {
        this.props.onChange(formchange);
    }

    async handleSubmit(values) {
        this.setState({...values})
        const details = await this.props.onSave(values)
        const {address, phoneNumber} = details
        this.setState({...address, phoneNumber, view: true, showNotification: true})
    }

    renderNotification() {
        if (!this.state.showNotification) {
            return ('')
        }
        return (
            <SaveConfirmation msg={MsgSaveAddress} onClose={this.handleNotificationClose}/>
        )
    }

    handleNotificationClose() {
        this.setState({
            showNotification: false
        });
    }

    handleOnEdit(event) {
        event.preventDefault()
        this.setState({view: false})
    }

    render() {
        if (this.state.view) {
            return this.renderView()
        }
        return (
            <Formik
                initialValues={this.state}
                onSubmit={this.handleSubmit}
                validate={this.onSubmit}
                validateOnChange={false}
                validationSchema={this.schema}
            >
                {({
                      handleChange, touched, errors,
                      handleBlur, handleSubmit, values
                  }) => {
                    const equal = (key) => {
                        return isEqual(get(values, key), get(this.state, key))
                    }

                    const formSame = () => {
                        return equal('street_address') && equal('locality')
                            && equal('region') && equal('postal_code') && equal('phoneNumber')
                    }
                    const submitDisabled = formSame()
                    this.handleChange(!submitDisabled);

                    return (
                        <form className="detailsForm" method="post" onSubmit={this.handleSubmit}>
                            <div className="halfArea">
                                <div className="groupRow">
                                    <label>Street address</label>
                                    <Input
                                        name='street_address'
                                        type='text'
                                        value={values.street_address}
                                        onChange={handleChange('street_address')}/>
                                    {errors.street_address && (
                                        <div className="validationText">{errors.street_address}</div>)}
                                </div>
                                <div className="groupRow">
                                    <label>City</label>
                                    <Input
                                        name='locality'
                                        type='text'
                                        value={values.locality}
                                        onChange={handleChange('locality')}/>
                                    {errors.locality && (<div className="validationText">{errors.locality}</div>)}
                                </div>
                                <div className="groupRow">
                                    <label>State</label>
                                    <Input name="state" type="select" value={values.region}
                                           onChange={handleChange('region')}>
                                        {
                                            this.states.map(code => {
                                                return (<option key={code} value={code}>{code}</option>)
                                            })
                                        }
                                    </Input>
                                </div>
                            </div>
                            <div className="halfArea">
                                <div className="groupRow">
                                    <label>Zip</label>
                                    <Input
                                        name='postal_code'
                                        type='text'
                                        value={values.postal_code}
                                        onChange={handleChange('postal_code')}/>
                                    {errors.postal_code && (<div className="validationText">{errors.postal_code}</div>)}
                                </div>
                                <div className="groupRow">
                                    <label>Phone number</label>
                                    <Input
                                        name='phoneNumber'
                                        type='text'
                                        value={values.phoneNumber}
                                        onChange={handleChange('phoneNumber')}/>
                                    {errors.phoneNumber && (<div className="validationText">{errors.phoneNumber}</div>)}
                                </div>
                                <div className="groupRow rightAlign bottom">
                                    <input type="submit" onClick={handleSubmit} disabled={submitDisabled} value="Save"/>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        )
    }

    renderView() {
        return (
            <>
                {this.renderNotification()}
                <form className="detailsForm" method="post" name="">
                    <div className="groupRow">
                        <div className="addressArea">
                            <div className="btnTop">
                                <a href="/" onClick={this.handleOnEdit}>
                                    <EditRoundedIcon
                                        style={{
                                            marginRight: '5px',
                                        }}
                                    />
                                    Edit
                                </a>
                            </div>
                            <p>{this.state.firstName} {this.state.lastName}<br/>
                                {this.state.street_address}<br/>
                                {this.state.locality}, {this.state.region} - {this.state.postal_code}<br/>
                                {this.state.phoneNumber}</p>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}

export default AddressBook
