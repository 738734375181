import React, {useEffect, useState} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

const CustomRedirect = ({ redirectUrl, isNewWindow }) => {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const openWindow = () => {
            if (isNewWindow) {
                window.open(redirectUrl, '_blank');
            } else {
                window.location.href = redirectUrl;
            }
        };
        (async () => {
        await openWindow();
        setIsLoading(false);
        })();
    }, [redirectUrl, isNewWindow]);

    return isLoading && <CircularProgress className="loading-button-spinner"/>;
};

export default CustomRedirect;

