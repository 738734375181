/* eslint-disable jsx-a11y/anchor-is-valid */
import removeClass from "../../util/RemoveCSS";
import RemoveCSS from "../../util/RemoveCSS";
import ChangePassword from './ChangePassword'
import ConfirmChange from './ConfirmChange'
import {Auth} from "aws-amplify";
import UserProfile from './UserProfile'
import AddressBook from './AddressBook'
// eslint-disable-next-line no-unused-vars
import React from "react";
import {getMinimalUserAttributes, getUserAddress, getUserAttributes, getUserDetails} from "./CongintoHelper";
import {manipulatePhoneNumber} from "../../config/config";
import ApiService from "../../ApiService";
import "./style.css"
import moment from "moment/moment";


const ProfileTab = {
    Details: 'Details',
    Password: 'Password',
    AddressBook: 'AddressBook'
}

RemoveCSS();
document.body.classList.add("body_settings");

class UserSettings extends React.Component {
    formchange = false;

    constructor(props) {
        super(props);
        this.state = {
            tab: ProfileTab.Details,
            nextTab: '',
            unsavedDialog: false,
            user: null,
            details: null
        }
        this.saveProfile = this.saveProfile.bind(this)
        this.onFormChange = this.onFormChange.bind(this)
        this.onTabSelect = this.onTabSelect.bind(this)
        this.updatePassword = this.updatePassword.bind(this)
        this.navigateProceed = this.navigateProceed.bind(this)
        this.navigateCancel = this.navigateCancel.bind(this)
        this.updateAddress = this.updateAddress.bind(this)
        this.getUpdatedUserDetails = this.getUpdatedUserDetails.bind(this)
    }

    componentDidMount() {
        const updateState = (user, details) => {
            this.setState({user, details})
        }
        Auth.currentAuthenticatedUser()
            .then((user) => {
                ApiService.getPatientAfterSessRef().then(res => {
                    const profile = res.data;
                    updateState(user, profile)
                })
            }).catch(err => {
                console.error(err);
        });
    }

    onFormChange(formchange) {
        this.formchange = formchange
    }

    onTabSelect(tab) {
        const unsavedDialog = this.formchange && (tab !== this.state.tab)
        if (unsavedDialog) {
            this.setState({nextTab: tab, unsavedDialog})
        } else {
            this.setState({tab, nextTab: tab})
        }
    }

    async getUpdatedUserDetails(profileEdits){
        const patientDetails = await ApiService.getPatientAfterSessRef().then(resp => resp.data);
        const dob = moment(profileEdits?.dob || patientDetails.dob).format('YYYY-MM-DD');

        return {
            ...patientDetails,
            firstName: profileEdits?.firstName || patientDetails.firstName,
            lastName: profileEdits?.lastName || patientDetails.lastName,
            email: profileEdits?.email || patientDetails.email,
            dob: dob,
            allergies: profileEdits?.allergies || patientDetails.allergies,
        }
    };

    async saveProfile(profile, isEmailChange) {
        if(isEmailChange){
            const attributes = getMinimalUserAttributes(profile);
            const result = await Auth.updateUserAttributes(this.state.user, attributes);
        }
        const updatedDetails = await this.getUpdatedUserDetails(profile);
        const updatedUserDetails = await ApiService.updateUserHealthData(updatedDetails).then(resp => resp.data);
        const newuser = await Auth.currentAuthenticatedUser();
        this.setState({user: newuser, details: updatedUserDetails});
        return 'SUCCESS';
    }

    navigateProceed() {
        this.formchange = false
        this.setState({unsavedDialog: false, tab: this.state.nextTab})
    }

    navigateCancel() {
        this.setState({unsavedDialog: false})
    }

    async updateAddress(address) {
        const patientDetails = await ApiService.getPatientAfterSessRef().then(resp => resp.data);
        manipulatePhoneNumber(address);
        const payload = {
            ...patientDetails,
            phoneNumber: address?.phoneNumber,
            address: address
        };
        const updatedUserDetails = await ApiService.updateUserHealthData(payload).then(resp => resp.data);
        return updatedUserDetails;
    }

    async updatePassword(info) {
        const {currPassoword, newPassoword} = info
        try {
            const result = await Auth.changePassword(this.state.user, currPassoword, newPassoword)
            return result
        } catch (err) {
            const {code} = err
            switch (code) {
                case 'NotAuthorizedException':
                    return {status: 'Failure', message: 'Incorrect username or password.'}
                case 'InvalidParameterException' :
                    return {status: 'Failure', message: 'Password must have length greater than or equal to 6'}
                default:
                    return {status: 'Failure', message: 'Password update failed'}
            }
        }
    }

    render() {
        removeClass();
        document.body.classList.add("accountPages");
        if (!this.state.details) {
            return ('')
        }
        const fullname = `${this.state.details.firstName} ${this.state.details.lastName}`
        const tabClass = (tab) => {
            return tab === this.state.tab ? 'active' : ''
        }

        const getTabForm = () => {
            const {tab} = this.state
            switch (tab) {
                case ProfileTab.Details:
                    return (
                        <UserProfile details={this.state.details} onSave={this.saveProfile}
                                     onChange={this.onFormChange}/>
                    )
                case ProfileTab.Password:
                    return (
                        <ChangePassword details={this.state.details} onSave={this.updatePassword}
                                        onChange={this.onFormChange}/>
                    )
                default:
                    return (
                        <AddressBook details={this.state.details} onSave={this.updateAddress}
                                     onChange={this.onFormChange}/>
                    )
            }
        }

        const renderConfirm = () => {
            if (!this.state.unsavedDialog) {
                return ('')
            }
            return (
                <ConfirmChange
                    cancel={this.navigateCancel}
                    proceed={this.navigateProceed}
                />
            )
        }

        return (
            <div className="msg_panel panel-default">
                <div className="inbox_header">
                    <h1 className="inbox_header header_font font-black">Settings</h1>
                </div>
                <div className="accountPages">
                    <div className="wrapContent">
                        <div className="accountArea">
                            <aside className="leftMenuArea">
                                <div className="userPic">
                                    <div className="pcArea">
                                        <img src=".../../../public/img/Avatar.png" alt=""/>
                                    </div>
                                    <span className="font-black">Hi, {fullname}</span>
                                </div>
                                <div className="tabs">
                                    <ul>
                                        <li><a className={tabClass(ProfileTab.Details) } onClick={() => {
                                            this.onTabSelect(ProfileTab.Details)
                                        }}>Personal Information</a></li>
                                        <li><a className={tabClass(ProfileTab.Password)} onClick={() => {
                                            this.onTabSelect(ProfileTab.Password)
                                        }}>Change Password</a></li>
                                        <li><a className={tabClass(ProfileTab.AddressBook)} onClick={() => {
                                            this.onTabSelect(ProfileTab.AddressBook)
                                        }}>Address Book</a></li>
                                    </ul>
                                </div>
                            </aside>
                            <div className="formAreaRight">
                                {getTabForm()}
                            </div>
                        </div>
                    </div>
                </div>
                {renderConfirm()}
            </div>
        )
    }
}

export default UserSettings
