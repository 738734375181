import React, {useEffect, useRef, useState} from 'react';
import ApiService from "../../ApiService";
import {eligibleState, getStates, validZipCode} from '../../config/config';
import {useHistory, useLocation} from "react-router-dom";
import PassMemberTermsModal from '../signup/PassMemberTermsModal';
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {DateTime} from "luxon";
import useUserStore from "../../hook/useUserStore";
import { subYears } from 'date-fns';
import './style.css';
import DatePicker from "react-datepicker";
import Badge from 'react-bootstrap/Badge';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import {getPreparedPhoneNumber, validPhoneNumber} from "../../util/UtilFunctions";

const states = getStates();

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const HealthProfile = (props) => {
    const history = useHistory();
    const [snackOpen, setSnackOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [streetAddress, setStreetAddress] = useState("");
    const [streetAddress2, setStreetAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [allowedState, setAllowedState] = useState(true)
    const [zip, setZip] = useState("");
    const [validZip, setValidZip] = useState("")
    const [dob, setDob] = useState(null);
    const [tooYoung, setTooYoung] = useState(false);
    const [gender, setGender] = useState("");
    const [errors, setErrors] = useState([]);
    const [allergies, setAllergies] = useState("");
    const [race, setRaceValue] = useState('Unknown');
    const [ethnicity, setEthnicityValue] = useState('Unknown');
    const [symptoms, setSymptomsValue] = useState(null);
    const [preExisting, setPreExisting] = useState(null);
    const [medications, setMedicationsValue] = useState(null);
    const [pronoun, setPronounValue] = useState('');
    const [isPassMember, setPassMemberStatus] = useState(false);
    const [passMemberAck, setPassmemberAck] = useState(false);
    const [shareToPass, setShareToPass] = useState(false);
    const [preferredName, setPreferredName] = useState('');
    const errorRef = useRef(null);

    const location = useLocation();
    const nextPath = new URLSearchParams(location.search).get('next');
    const missingInfo = new URLSearchParams(location.search).get('missing-info');

    const ethnicityList = [
        {label: 'True', value: 'Hispanic or Latino', key: 'hispani'},
        {label: 'False', value: 'not Hispanic or Latino', key: 'not-hispani'},
        {label: 'Decline to State', value: 'Unknown', key: 'unknown'},
    ];

    const patient = useUserStore((state) => state.patient);
    const setPatient = useUserStore((state) => state.setPatient);

    document.body.classList.add("body_portal");

    useEffect(() => {
        if(!errors?.length) return;
        errorRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center'});
    }, [errors]);

    useEffect(() => {
        if(missingInfo === 'true' || missingInfo === true){
            setSnackOpen(true);
        }
        if(!patient) return;

        setPhoneNumber(patient.phoneNumber ?? '');
        setFname(patient.firstName ?? '');
        setLname(patient.lastName ?? '');
        setGender(patient.gender ?? '');
        if(patient.dob && patient.dob?.trim() !== ""){
            setDob(DateTime.fromISO(patient.dob).toJSDate());
        }


        setAllergies(patient.allergies ?? '');
        setEthnicityValue(patient.ethnicity ?? '');
        setRaceValue(patient.race ?? '');
        setSymptomsValue(patient.symptoms ?? '');
        setPreExisting(patient.preExisting ?? '');
        setMedicationsValue(patient.medications ?? '');
        setPronounValue(patient.pronoun ?? '');
        setPassmemberAck((patient?.isPassMember === 'true' || patient?.isPassMember === true) ? true : false);
        setShareToPass((patient?.shareToPass === 'true' || patient?.shareToPass === true) ? true : false);
        setPreferredName(patient.preferredName ?? '');

        const {address} = patient;
        setStreetAddress(address?.street_address ?? '');
        setStreetAddress2(address?.street_address2 ?? '');
        setCity(address?.locality ?? '');
        setState(address?.region ?? '');
        setZip(address?.postal_code ?? '');
        validZipCode(address?.postal_code && address?.postal_code?.trim() !== "");
        setValidZip(address?.postal_code ?? '');
    }, []);


    const ethnicityFormGroup = [];
    ethnicityList.forEach(et => {
        ethnicityFormGroup.push(
            <label for={et.key} class="radio-input">
                <input type="radio" id={et.key} name="ethnicityInput"
                       checked={et.value === ethnicity}
                       value={et.value} onChange={(e) => setEthnicityValue(e.target.value)}/>
                <span>{et.label}</span>
            </label>
        );
    });
    const raceList = [
        {label: 'American Indian or Alaska Native', key: 'alaska', value: 'American Indian or Alaska Native'},
        {label: 'Asian', value: 'Asian', key: 'asian'},
        {
            label: 'Native Hawaiian or Other Pacific Islander',
            key: 'Hawaiian',
            value: 'Native Hawaiian or Other Pacific Islander'
        },
        {label: 'Black or African-American', key: 'africa', value: 'Black or African-American'},
        {label: 'White', value: 'White', key: 'white'},
        {label: 'Other', value: 'Other', key: 'other'},
        {label: 'Decline to State', value: 'Unknown', key: 'unknown', checked: true}
    ];
    const raceFormGroup = [];
    raceList.forEach(et => {
        raceFormGroup.push(
            <label for={et.key} class="radio-input">
                <input type="radio" id={et.key} name="raceInput"
                       checked={et.value === race}
                       value={et.value} onChange={(e) => setRaceValue(e.target.value)}/>
                <span>{et.label}</span>
            </label>
        );
    });

    document.body.classList.add("body_login");

    const handleStateChange = (event) => {
        setAllowedState(eligibleState(event.target.value))
        setState(event.target.value)
    }

    const handleZipChange = (event) => {
        setValidZip(validZipCode(state, event.target.value))
        setValidZip(true);
        setZip(event.target.value.trim())
    }

    const backButton = () => {
        if (page > 1 && page <= 3) {
            setPage(page - 1);
        }
    };

    const handleCloseAck = (status) => {
        setShareToPass(status);
        setPassmemberAck(false)
    };

    const isTooYoung = () => {
        if(!dob) return false;
        const diffYrs = DateTime.now().diff(DateTime.fromJSDate(dob), 'years').years;
        return Math.abs(diffYrs) <= 15;
    }

    const handleSubmitPage2 = (event) => {
        setErrors([]);
        let errors = [];

        if (!streetAddress?.trim() || !city?.trim() || !state?.trim() || !zip || !phoneNumber){
            errors.push("Please enter all required fields.");
        }
        if (state === 'NY') {
            errors.push("Sorry, our service not currently providing in your state");
        } else if (!validZip) {
            errors.push("Please double check your zip code");
        } else if (phoneNumber && !validPhoneNumber(phoneNumber)) {
            errors.push("Invalid phone number format");
        }
        if(errors.length > 0){
            setErrors(errors);
            return;
        }
        setPage(3);
    }

    const handleSubmitPage1 = (event) => {
        event.preventDefault();
        setErrors([]);
        let errors = [];
        if(!gender) {
            errors.push("Please specify if vaginal swab would be appropriate!");
        }
        if (!pronoun?.trim() || !fname?.trim() || !lname?.trim() || !dob || !gender){
            errors.push("Please enter required fields.");
        } else if(dob && isTooYoung()){
            errors.push("The minimum age requirement of 15 to use our kits. If you have purchased one of our kits, please reach out to customer service to request a refund.");
        }
        if(errors.length > 0){
            setErrors(errors);
            return;
        }
        setPage(2);
    }

    const handleSubmitFinal = (event) => {
        setErrors([]);
        let errors = [];
        event.preventDefault();
        let address = {};
        address['locality'] = city;
        address['region'] = state;
        address['postal_code'] = zip;
        address['street_address'] = streetAddress;
        address['street_address2'] = streetAddress2;

        const prepPhoneNum = getPreparedPhoneNumber(phoneNumber);
        const payload = {
            userId: patient?.userId,
            'phoneNumber': prepPhoneNum?.startsWith('+1') ? prepPhoneNum : "+1" + prepPhoneNum,
            firstName: fname,
            lastName: lname,
            gender,
            genderAtBirth: gender,
            'dob': dob ? DateTime.fromJSDate(dob).toISODate() : "",
            address: address,
            name: fname + " " + lname,
            email: patient?.email,
            'allergies': !allergies ? 'none' : allergies,
            'ethnicity': ethnicity,
            'race': race,
            'symptoms': !symptoms ? 'none' : symptoms,
            'preExisting' : !preExisting ? 'none' : preExisting,
            'medication': !medications ? 'none' : medications,
            'pronoun': pronoun,
            'isPassMember': isPassMember.toString(),
            'isShareWIthPass': shareToPass.toString(),
            'preferredName': preferredName
        };
        ApiService.updateUserHealthData(payload)
            .then(res => {
                setPatient(res.data);
                sessionStorage.setItem('h_prof', 'true');
                history.push(getNextPath());
            })
            .catch(err => {
                errors.push('Error saving your info, please try again!')
            });
        if(errors.length > 0){
            setErrors(errors);
            return;
        }
        history.push(getNextPath());
    }

    const getNextPath = () => {
        if(!nextPath?.trim() || nextPath === '/') return '/';
        return nextPath.startsWith('/') ? nextPath : `/${nextPath}`;

    }

    return (
        <div className="form" id="profile-form">
            <div className="form-body profileUpdate-body">
                    <Snackbar open={snackOpen} onClose={() => setSnackOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <Alert onClose={() => setSnackOpen(false)} severity="warning">
                            Please update your missing profile information
                        </Alert>
                    </Snackbar>
                {errors?.map((err, index) => {
                    return (
                        <div className="signup-errors" ref={index === 0 ? errorRef : null} key={index}>
                            {err}
                        </div>
                    );
                })}
            <div class="row w-100-mobile">
            <span className="updateProfile_text">Update Profile</span>
            {
                page === 1 ? 
                    <form id="signupFormPage1">
                        <div className="form-group">
                            <label for="pronoun" className="profile-label">Addressed as</label>
                            <select id="pronoun" value={pronoun}
                                    className={!pronoun?.trim() && errors?.length > 0 ? `form-control profileUpdate-form red-outline` : `form-control profileUpdate-form`}
                                    onChange={(e) => setPronounValue(e.target.value)}
                                    required >
                                <option value="">Select an option</option>
                                <option value="he/him">He/Him</option>
                                <option value="she/her">She/Her</option>
                                <option value="they/them">They/Them</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label for="fname" className="profile-label fw-300">Legal First Name</label>
                            <input
                                type="text"
                                id="text"
                                className={!fname?.trim() && errors?.length > 0 ? `form-control profileUpdate-form red-outline` : `form-control profileUpdate-form`}
                                required="required"
                                value={fname}
                                onInput={(e) => setFname(e.target.value.trim())}
                            />
                        </div>
                        <div className="form-group">
                            <label for="lname" className="profile-label fw-300">Legal Last Name</label>
                            <input
                                type="text"
                                id="text"
                                className={!lname?.trim() && errors?.length > 0 ? `form-control profileUpdate-form red-outline` : `form-control profileUpdate-form`}
                                required
                                value={lname}
                                onInput={(e) => setLname(e.target.value.trim())}
                            />
                        </div>
                        <div className="form-group">
                            <label for="preferred_name" className="profile-label fw-300">Preferred Name</label>
                            <input
                                type="text"
                                id="text"
                                className="form-control profileUpdate-form"
                                value={preferredName}
                                onInput={(e) => setPreferredName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="dob" className="profile-label fw-300">Please confirm your birth date</label>
                            <DatePicker
                                id="dob"
                                form="signupFormPage1"
                                className={(dob === null && errors?.length > 0) ? `form-control profileUpdate-form red-outline` : `form-control profileUpdate-form`}
                                value={dob ? dob : null}
                                selected={dob ? dob : null}
                                onChange={date => {
                                    setDob(date);
                                }}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                maxDate={new Date()}
                                required 
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="gender" className="profile-label fw-300">Do you have a vagina?</label>
                            <select id="pronoun" value={gender}
                                    className={(gender === "" && errors?.length > 0) ? `form-control profileUpdate-form red-outline` : `form-control profileUpdate-form`}
                                    onChange={(e) => setGender(e.target.value)}
                                    required >
                                <option value="">Select an option</option>
                                <option value="female">Yes</option>
                                <option value="male">No</option>
                            </select>
                        </div>
                        <div id="profileButtonRow">
                            <button 
                                form="signupFormPage1"
                                type="submit" onClick={handleSubmitPage1}
                                className="btn socialbutton-customizable"
                                id="socialbutton-customizable"
                            >
                                        Next
                            </button>
                        </div>
                    </form>
                : page === 2 ?
                    <form id="signupFormPage2" noValidate>
                        <div className="form-group">
                            <label for="street_address" className="profile-label fw-300">Street Address</label>
                            <input
                                type="text"
                                id="text"
                                className={!streetAddress?.trim() && errors?.length > 0 ? `form-control profileUpdate-form red-outline` : `form-control profileUpdate-form`}
                                required
                                value={streetAddress}
                                onInput={(e) => setStreetAddress(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label for="street_address2" className="profile-label">Address Line 2</label>
                            <input
                                type="text"
                                id="text"
                                className="form-control profileUpdate-form"
                                required
                                value={streetAddress2}
                                onInput={(e) => setStreetAddress2(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label for="city" className="profile-label fw-300">City</label>
                            <input
                                type="text"
                                id="text"
                                className={!city?.trim() && errors?.length > 0 ? `form-control profileUpdate-form red-outline` : `form-control profileUpdate-form`}
                                required
                                value={city}
                                onInput={(e) => setCity(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label for="state" className="profile-label fw-300">State</label>
                            <select id="pronoun" value={state} name="state"
                                    className={(!state && errors?.length > 0) || !allowedState ? `form-control profileUpdate-form red-outline` : `form-control profileUpdate-form`}
                                    onChange={(e) => handleStateChange(e)}>
                                <option value="" className="form-control profileUpdate-form"></option>
                                {states.map(state => <option value={state}>{state}</option>)}
                            </select>
                        </div>
                        <div className="form-group">
                            <label for="zip" className="profile-label fw-300">Zip Code</label>
                            <input
                                type="text"
                                id="text"
                                className={(!zip?.trim() && errors?.length > 0) || (!validZip && errors) ? `form-control profileUpdate-form red-outline` : `form-control profileUpdate-form`}
                                required
                                value={zip}
                                onChange={(e) => handleZipChange(e)}
                            />
                        </div>
                        <div className="form-group">
                            <label for="phoneNumber" className="profile-label fw-300">Phone Number</label>
                            <input
                                type="text"
                                id="text"
                                className={!phoneNumber?.trim() && errors?.length > 0 ? `form-control profileUpdate-form red-outline` : `form-control profileUpdate-form`}
                                placeholder="(XXX) XXX-XXXX"
                                required
                                value={phoneNumber}
                                onInput={(e) => setPhoneNumber(e.target.value)}
                            />
                        </div>
                        <div id="profileButtonRow">
                            <div id="backButton" className="alt-socialbutton-customizable"
                                onClick={backButton}>Back
                            </div>
                            <button id="socialbutton-customizable" type="button" onClick={handleSubmitPage2}
                                    className="socialbutton-customizable">Next
                            </button>
                        </div>
                    </form>
                : <form id="signupFormPage3" noValidate onSubmit={handleSubmitFinal}>
                        <div className="form-group">
                            <label for="allergies" className="profile-label">Any allergies ?</label>
                            <textarea
                                id="allergies"
                                className="signup_text_box"
                                placeholder="List the here."
                                required
                                onInput={(e) => setAllergies(e.target.value)} >
                                {allergies}
                            </textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="symptoms" className="profile-label">Any symptoms ?</label>
                            <textarea
                                className="signup_text_box"
                                placeholder="List the here."
                                required
                                id="symptoms"
                                onInput={(e) => setSymptomsValue(e.target.value)} >
                                {symptoms}
                            </textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="pre-existing-conditions" className="profile-label">Any Pre-existing conditions ?</label>
                            <textarea
                                className="signup_text_box"
                                placeholder="List the here."
                                required
                                id="pre-existing"
                                onInput={(e) => setPreExisting(e.target.value)}>
                                {preExisting}
                            </textarea>
                        </div>
                        <div className="form-group">
                            <label for="medications" className="profile-label">Any current medications ?</label>
                            <textarea
                                className="signup_text_box"
                                placeholder="List the here."
                                required
                                id="medications"
                                onInput={(e) => setMedicationsValue(e.target.value)}
                                >
                                {medications}
                            </textarea>
                        </div>
                        <div className="form-group">
                            <label className="profile-label">Are you of Hispanic or Latinx origin?</label>
                            <div className='radioList'>
                                {ethnicityFormGroup}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="profile-label">Do you identify with any of the following groups?</label>
                            <div className='radioList'>
                                {raceFormGroup}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="profile-label">Are you a member of PASS?
                                &nbsp;&nbsp;
                                {/*<button ref={passLearnMoreTarget} onClick={() => setShowPassLearnMore(!showPassLearnMore)}>*/}
                                {/*    <Badge pill bg="info">Learn more</Badge>*/}
                                {/*</button>*/}
                                </label>
                            <div className='radioList'>
                                <label class="radio-input">
                                    <input type="radio" name="passFormInput" value="true"
                                        checked={isPassMember === true} onChange={() => {
                                        setPassMemberStatus(true);
                                        setPassmemberAck(true);
                                    }}/>
                                    &nbsp; Yes
                                </label>
                                <label class="radio-input">
                                    <input type="radio" name="passFormInput" value="false"
                                        checked={isPassMember === false}
                                        onChange={() => setPassMemberStatus(false)}/>
                                    &nbsp; No
                                </label>
                            </div>
                        </div>
                        <div id="profileButtonRow">
                            <div id="backButton" className="alt-socialbutton-customizable"
                                    onClick={backButton}>Back
                            </div>
                            <button 
                                type="submit"
                                id="socialbutton-customizable"
                                className="socialbutton-customizable"
                            >
                                Submit
                            </button>                       
                        </div>
                </form> 
            }
            </div>   
            <PassMemberTermsModal
                show={passMemberAck}
                handleClose={handleCloseAck} />
            </div>
        </div>
    );
}
export default HealthProfile;