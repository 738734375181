import Autolinker from 'autolinker';
import DOMPurify from "dompurify";

const linkedText = (text) => {
    var linked = Autolinker.link( DOMPurify.sanitize(text), { 
        newWindow: true,
        urls: { schemeMatches: true, wwwMatches: true, tldMatches: true },
        className: 'custom-link'
    });
    return linked
}
export default linkedText