import React from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line no-unused-vars
import * as Sentry from "@sentry/react";
// eslint-disable-next-line no-unused-vars
import {Integrations} from "@sentry/tracing";
import App from './App';
import Amplify from "aws-amplify";
import config from './aws-exports'

// Sentry.init({
//     dsn: "https://9f6ed80362e94d5fb339ac633694fb76@o507863.ingest.sentry.io/5599541",
//     environment: process.env.REACT_APP_ENV,
//     autoSessionTracking: true,
//     integrations: [
//         new Integrations.BrowserTracing(),
//     ],
//
//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 0.25,
// });

const configUpdate = config;
Amplify.configure(configUpdate);

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
