import React, {useState, useEffect} from "react";
import ApiService from "../../ApiService";
import MessageDetail from "./MessageDetail";
import moment from "moment";

const SentMessage = () => {
    const [messages, setmessages] = useState(null);
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState(null);
    const [messageUUID, setMessageUUID] = useState(null);

    useEffect(() => {
        async function getSentMessages() {
            try {
                const getSentMessages = await ApiService.getSentMessages();
                setLoading(false);
                setmessages(getSentMessages.data);
            } catch(ex) {
                console.error(ex);
            }
            setLoading(false);
        }
        getSentMessages();
    }, []);

    function fetchMessage(messageUUID) {
        setMode('VIEW_MESSAGE_DETAIL');
        setMessageUUID(messageUUID);
    }

    function renderTableData() {
        if (messages) {
            return messages.map((result, index) => {
                const {messageUUID, subject, to, createdDateTime} = result;
                if (messages[index].unread) {
                    return (
                        <tr key={messageUUID} onClick={() => fetchMessage(messageUUID)}>
                            <td key={to}>{to}</td>
                            <td key={subject}>{subject}</td>
                            <td key={createdDateTime}>
                                {moment(createdDateTime).format("ddd MMM D, YYYY LT")}
                            </td>
                        </tr>
                    )
                } else {
                    return (
                        <tr key={messageUUID} onClick={() => fetchMessage(messageUUID)}>
                            <td key={to}>
                                {to}
                            </td>
                            <td key={subject}>{subject}</td>
                            <td key={createdDateTime}>
                                {moment(createdDateTime).format("ddd MMM D, YYYY LT")}
                            </td>
                        </tr>
                    )
                }
            })
        }
    }

    if (loading) {
        return (
            <div class="load_message">
                <p class="load_text">
                    <i class="fas fa-spinner fa-pulse"></i>
                </p>
            </div>
        )
    } else if (!loading && mode !== 'VIEW_MESSAGE_DETAIL') {
        return (
            <table border="1" class="message_index">
                <thead>
                    <th class="message_index_sender table_row">Sender</th>
                    <th class="message_index_subject table_row">Subject</th>
                    <th class="message_index_date table_row">Date/Time</th>
                </thead>
                {renderTableData()}
            </table>
        )
    } else if (mode === 'VIEW_MESSAGE_DETAIL') {
        return (
            <MessageDetail messageUUID={messageUUID} sentMessage={true}/>
        )
    }
}
export default SentMessage;
