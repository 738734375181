import React from "react"
import ApiService from "../../ApiService";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import {Form} from 'react-bootstrap'
import {Input} from "reactstrap";
import {get, isEqual} from "lodash";
import ImageUploading from 'react-images-uploading';
import SaveConfirmation from "./SaveConfirmation";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import './style.css';
import UploadImagePopOver from "./UploadImagePopOver";
import EditRoundedIcon from '@material-ui/icons/EditRounded';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MsgSaveProfile = 'Details are updated successfully'

class UserProfile extends React.Component {

    details = null

    constructor(props) {
        super(props);
        const {details} = props
        this.details = details
        this.state = {
            ...details,
            showNotification: false,
            view: true,
            profilePic: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png',
            images: [],
            imageSaved: false
        };
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleFormChange = this.handleFormChange.bind(this)
        this.handleNotificationClose = this.handleNotificationClose.bind(this)
        this.handleOnEdit = this.handleOnEdit.bind(this)
        this.onChange = this.onChange.bind(this);
        this.handleSnackClose = this.handleSnackClose.bind(this);
    }

    async handleSubmit(e) {
        e.preventDefault();
        const response = await this.props.onSave(this.state, !(isEqual(get(this.state, 'email'), get(this.details, 'email'))));
        this.details = {...this.details, ...this.state}
        if (response === 'SUCCESS') {
            this.setState({
                showNotification: true,
                view: false
            });
        }
        window.scrollTo(0, 0);        
    }

    handleSnackClose() {
        this.setState({
            imageSaved: false
        });
    };

    componentDidMount() {
        ApiService.getUserPic().then(image => {
            this.setState({profilePic: image.data})
        })
        .catch(err => {
            console.log("Error fetching User profile picture!");
        });
    }

    handleOnEdit() {
        this.setState({
            view: false
        });
    }

    handleNotificationClose() {
        this.setState({
            showNotification: false
        });
    }

    handleFormChange(formchange) {
        this.props.onChange(formchange);
    }
    onChange(imageList) {
        if(imageList) {
            this.setState({
                profilePic: imageList[0].data_url,
                images: imageList
            });
        }
        
        try {
            // eslint-disable-next-line no-unused-vars
            ApiService.postUserPic({"image": imageList[0].data_url}).then(image => {
                this.setState({imageSaved: true})
            })
        } catch(ex) {
            console.error(ex);
        }
    };

    render() {
        const equal = (key) => {
            return isEqual(get(this.state, key), get(this.details, key))
        }

        const formSame = () => {
            return equal('firstName') && equal('lastName') && equal('email')
                && equal('birthdate') && equal('gender') && equal('allergies') && equal('dob')

        }
        const showNotification = () => {
            if (!this.state.showNotification) {
                return ('')
            }
            return (
                <SaveConfirmation msg={MsgSaveProfile} onClose={this.handleNotificationClose}/>
            )
        }
        const submitDisabled = formSame()
        this.handleFormChange(!submitDisabled);
        return (
            <>
                {showNotification()}
                <button className="editBtn" onClick={this.handleOnEdit}>
                        <EditRoundedIcon 
                            style={{ marginRight: '5px' }}
                        />
                    Edit</button>
                <form className="detailsForm" onSubmit={this.handleSubmit}>
                    <div className="halfArea">
                        <div className="groupRow">
                            <label>Legal First Name</label>
                            <Input name='firstName' disabled={this.state.view} value={this.state.firstName}
                                   onChange={(e) => {
                                       this.setState({'firstName': e.target.value})
                                   }} placeholder="Legal First Name"/>
                        </div>
                        <div className="groupRow">
                            <label>Legal Last Name</label>
                            <Input name='lastName' disabled={this.state.view} value={this.state.lastName}
                                   onChange={(e) => {
                                       this.setState({'lastName': e.target.value})
                                   }} placeholder="Legal Last Name"/>
                        </div>
                        <div className="groupRow">
                            <label>Email address</label>
                            <Input value={this.state.email} type="email" disabled={true} onChange={(e) => {
                                this.setState({'email': e.target.value})
                            }} placeholder="E-Mail"/>
                        </div>
                        <Form.Group controlId="formDOB" className="groupRow">
                            <label>Date of birth</label>
                            <DatePicker placeholderText="DOB" name='birthdate' disabled={this.state.view}
                                        maxDate={new Date()}
                                        selected={moment(this.state.dob, "YYYY-MM-DD").toDate()}
                                        onChange={date => {
                                            this.setState({'dob': date})
                                        }}
                            />
                        </Form.Group>
                    </div>
                    <div className="halfArea">
                        <div className="groupRow">
                            <ImageUploading
                                value={this.state.images}
                                onChange={this.onChange}
                                dataURLKey="data_url"
                                resolutionType='ratio'
                            >
                                {({
                                onImageUpload,
                                isDragging,
                                dragProps,
                                errors
                                }) => (
                                <div className="upload__image-wrapper">
                                    <a
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                    >
                                        <img className="user-profile-img" src={this.state.profilePic} alt="" width="200" height="200" />
                                        <div className="change-image-text"><p>Upload Image <UploadImagePopOver/> </p></div>
                                        {errors &&
                                            <div className="change-image-text color-red">
                                                {errors.resolution && <span>***Uploaded image must have a 1:1 ratio***</span>}
                                            </div>
                                        }
                                    </a>
                                </div>
                                )}
                            </ImageUploading>
                        </div>
                        <div className="groupRow">
                            <label>Allergies</label>
                            <Form.Control as="textarea" value={this.state.allergies} rows={3}
                                          disabled={this.state.view}
                                          onChange={(e) => {
                                              this.setState({'allergies': e.target.value})
                                          }
                                          }/>
                        </div>
                        <div className="groupRow rightAlign">
                            <input type="submit" disabled={submitDisabled} value="Save"/>
                        </div>
                    </div>
                </form>
                <Snackbar autoHideDuration={6000} open={this.state.imageSaved} onClose={this.handleSnackClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert onClose={this.handleSnackClose} severity="success">
                    Your new image has been saved!
                    </Alert>
                </Snackbar>
            </>
        )
    }
}

export default UserProfile
