/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import ApiService from '../../ApiService';
import ReactTooltip from 'react-tooltip';
import Message from "./Message";
import NewMessage from "./NewMessage";
import SentMessage from "./SentMessage";
import removeClass from "../../util/RemoveCSS";
import {useHistory} from "react-router-dom";
import url from "../../util/routeURL"

const Outbox = () => {
    const [messages, setmessages] = useState(null);
    const [msgNum, setMsgNum] = useState("");
    const [sentMsg, setSentMsg] = useState(true);
    const [newMsg, setNewMsg] = useState(false);
    const [inboxTab, setInboxTab] = useState(false);
    const [componentEl, setComponentEl] = useState(null);
    const [registeredKit, setRegisteredKit] = useState(null);

    const history = useHistory();

    removeClass();
    document.body.classList.add("body_inbox");

    useEffect(() => {
        async function getMessage() {
            try {
                const getMessage = await ApiService.getMessages();
                setmessages(getMessage.data);
                const getKitExists = await ApiService.getKitExists();
                setRegisteredKit(getKitExists.data);
            } catch(ex) {
                console.error(ex);
            }
        }
        getMessage();
    }, []);

    useEffect(() => {
        var unreadMessageCount = 0;
        if (messages) {
            for (let m of messages) {
                if (m.unread) {
                    unreadMessageCount++;
                }
            }
            setMsgNum(unreadMessageCount);
        }
    }, [messages]);

    const inboxButton = () => {
        history.push(url.inbox)
    };

    const newButton = () => {
        if (registeredKit) {
            setInboxTab(false);
            setSentMsg(false);
            setNewMsg(true);
        } else {
            setNewMsg(false);
        }
    };

    useEffect(() => {
        if (!registeredKit) {
            document.getElementById("new-message-button").disabled = true;
        } else {
            document.getElementById("new-message-button").disabled = false;
        }
    }, [registeredKit])

    const sentMsgButton = () => {
        window.location.reload()
    };

    useEffect(() => {
        if (inboxTab) {
            setComponentEl(<Message />);
        }
    }, [inboxTab]);

    useEffect(() => {
        if (newMsg) {
            setComponentEl(<NewMessage />);
        }
    }, [newMsg]);

    useEffect(() => {
        if (sentMsg) {
            setComponentEl(<SentMessage />);
        }
    }, [sentMsg]);

    return (
        <div className="msg_panel panel-default">
            <div className="inbox_header">
                <h1 className="inbox_header header_font">Messages</h1>
            </div>
            <div className="panel-flex panel-divider">
                <div className="panel-buttons-container">
                    <button className="message_buttons" onClick={() => inboxButton()}>
                        <p className="message_button_text">Inbox </p>
                        <p className="message_button_text">{msgNum > 0 ? msgNum : ""}</p>
                    </button>
                    <button className="message_buttons" onClick={() => sentMsgButton()}>
                        <p className="message_button_text">Sent </p>
                    </button>
                    { !registeredKit &&
                        <a data-tip="Register a kit to send a message">
                            <button id="new-message-button" className="message_buttons new_msg_button" onClick={() => newButton()}>
                                <p id="new-message-span" className="message_button_text">New </p>
                                <p id="new-message-text" className="message_button_text">+</p>
                                <ReactTooltip place="bottom" effect="solid"/>
                            </button>
                        </a>
                    }
                    { registeredKit &&
                        <button id="new-message-button" className="message_buttons new_msg_button" onClick={() => newButton()}>
                            <p id="new-message-span" className="message_button_text">New </p>
                            <p id="new-message-text" className="message_button_text">+</p>
                        </button>
                    }
                </div>
                {componentEl}
            </div>
        </div>
    )
};
export default Outbox;
