import React, {useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import ApiService from "../../ApiService";
import {useHistory} from "react-router-dom";
import url from "../../util/routeURL";
import RegisterKitModal from './RegisterKitModal';
import PassMemberProfilePicModal from './PassMemberProfilePicModal';
import CircularProgress from '@material-ui/core/CircularProgress';
import RemoveCSS from "../../util/RemoveCSS";
import './style.css'

const PortalLogin = () => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [newMessages, setNewMessages] = useState(0);
    const [appointments, setAppointments] = useState(null);
    const [show, setShow] = useState(false);
    const [showProfilePicModal, setProfilePicModalStatus] = useState(false);
    const history = useHistory();

    const handleClose = () => setShow(false);

    const handleProfilePicModalClose = () => setProfilePicModalStatus(false);

    RemoveCSS();
    document.body.classList.add("body_portal");

    useEffect(() => {
        async function getMessageCount() {
            try {
                const getMessageCount = await ApiService.getMessageCount();
                let newMessages = getMessageCount.data;
                if (newMessages >= 1) {
                    setNewMessages(newMessages);
                }
                setAppointments(appointments);
            } catch (ex) {
                console.error(ex);
            }
            setLoading(false);
        }

        getMessageCount();
        Auth.currentAuthenticatedUser()
            .then(user => {
                setUser(user);
                checkForProfilePic(user);
            }).catch(err => {
            console.error(err);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const checkForProfilePic = (user) => {
        if (user && user.attributes['custom:isPassMember'] === 'true') {
            ApiService.getUserPic().then(image => {
            })
                .catch(err => {
                    console.info("Error fetching User profile picture!");
                    setProfilePicModalStatus(true);
                })
        }
    }
    if (user) {
        return (
            <div id="user_dashboard" className="panel-default mw-100 mh-100">
                <div className="panel-body z-1">
                    <div className="panel_header_container">
                        <div className="panel-header">Welcome, {user.attributes.given_name}</div>
                        <p className="header-sub">We're here to help</p>
                    </div>

                    <RegisterKitModal handleClose={handleClose} show={show} goTo={() => history.push(url.registerkit)}/>
                    <PassMemberProfilePicModal handleClose={handleProfilePicModalClose} show={showProfilePicModal}
                                               goTo={() => history.push(url.setting)}/>

                    <div className="dashboard">
                        {loading &&
                            <CircularProgress className="loading-button-spinner"/>
                        }
                        {!loading &&
                            <div className="dashboard_panel dash_buttons">
                                    <>
                                        <button id="message-button" className='panel_text new_message_button'
                                                onClick={() => history.push(url.inbox)}>{newMessages > 0 ? `${newMessages} Messages` : `Messages`}</button>
                                        <button disabled id="appointment-button" className="panel_text panel_button"
                                                onClick={() => history.push(url.appointments)}>{appointments} Appointments
                                        </button>
                                        <button id="history-button" className="panel_text panel_button"
                                                onClick={() => history.push(url.history)}>History
                                        </button>
                                        <button id="settings-button" className="panel_text panel_button"
                                                onClick={() => history.push(url.setting)}>Settings
                                        </button>
                                    </>
                                
                            </div>
                        }
                    </div>
                </div>
                <div className="curved-welcome">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 219">
                        <path fill="rgba(238, 129, 85, 0.3)" fillOpacity="1"
                              d="M0,64L120,96C240,128,480,192,720,181.3C960,171,1200,85,1320,42.7L1440,0L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
                    </svg>
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default PortalLogin;
