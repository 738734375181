import React, {useEffect, useState} from "react";
import ApiService from "../../ApiService";
import ReplyMessage from "./ReplyMessage";
import {Button, Modal} from "react-bootstrap";
import moment from "moment";
import linkedText from "../../util/linked-text";
import {useHistory} from "react-router-dom";
import "./MessageTestDetail.css"


const MessageTestDetail = (props) => {
    const {messageUUID, message, sentMessage} = props;
    const [replyMessageComponent, setReplyMessageComponent] = useState(null);
    const [show, setShow] = useState(true);
    const [clickedAckModal, setClickedAckModal] = useState(false);
    const [isShowReportBtn, setIsShowReportBtn] = useState(false);
    const data = props.message.testResult.results;

    const handleClose = () => setShow(false);
    const handleCloseAck = () => setClickedAckModal(false);
    const handleShow = () => setShow(true);
    const history = useHistory();

    useEffect(() => {
        const {messageType, testResultHasAttachments } = props.message;
        const isShowReportBtn = testResultHasAttachments === true
            && messageType?.toUpperCase() === 'TEST_RESULT';
        setIsShowReportBtn(isShowReportBtn);
    },[props.message]);

    function reply() {
        if (document.getElementById("ackcheckbox") && document.getElementById("ackcheckbox").checked) {
            setReplyMessageComponent(<ReplyMessage messageUUID={messageUUID}/>);
        }
    }

    useEffect(() => {
        data.forEach(test => {
            const resultString = test.result.toLowerCase();
            if (resultString === "not detected" || resultString === "non-reactive" || resultString === 'negative') {
                document.getElementById("negRes").innerHTML +=
                    (`<div class="display_results neg_display">${test.testName}</div>`); //appends html
                document.getElementById("negative-test").style.display = "block"; //displays test header
            } else if (resultString === "detected" || resultString === "reactive" || resultString === 'positive') {
                document.getElementById("posRes").innerHTML +=
                    (`<div class="display_results pos_display" >${test.testName}</div>`);
                document.getElementById("positive-test").style.display = "block";
            } else if (resultString === "indeterminate") {
                document.getElementById("notRes").innerHTML +=
                    (`<div class="display_results not_display" >${test.testName}</div>`);
                document.getElementById("indeterminate-test").style.display = "block";
            }
        })
    }, [data]);

    const ackResults = () => {
        document.getElementById("ackcheckbox").disabled = true;

        (async function acknowledgeMessage() {
            try {
                // eslint-disable-next-line no-unused-vars
                const putMessage = await ApiService.acknowledgeMessage(messageUUID);
            } catch (ex) {
                console.error(ex);
            }
            setClickedAckModal(true);
        })();
    };

    useEffect(() => {
        if (document.getElementById("ackcheckbox") && message.readDateTime) {
            document.getElementById("ackcheckbox").checked = true
            document.getElementById("ackcheckbox").disabled = true
        } else if (document.getElementById("ackcheckbox")) {
            document.getElementById("ackcheckbox").checked = false
            document.getElementById("ackcheckbox").disabled = false
        }
    }, [message]);

    useEffect(() => {
        if (message.readDateTime) {
            handleClose();
        } else {
            handleShow();
        }
    }, [message.readDateTime]);

    if (!message) {
        return (<div className="open_message">Fetching Message</div>);
    } else {
        const formattedText = linkedText(message.testResult.externalNotes)
        return (
            <>
                <div className="open_message" id="open_message">

                    {replyMessageComponent}
                    <div className="message_header">
                        <div className="from_header_el header-from">{message.from}</div>
                        <div className="header-subject">{message.subject}</div>
                        <div
                            className="header-date">{moment(message.createdDateTime).format("ddd MMM D, YYYY LT")}</div>
                        {
                            sentMessage !== true ?
                                <button onClick={reply} id="msg_reply" className="replyButton">Reply <i
                                    className="fas fa-reply"></i></button>
                                : null
                        }
                    </div>
                    {/* </div> */}
                    <div className="message-margin">
                        <div className="message_content">
                            <div
                                dangerouslySetInnerHTML={{__html: formattedText}}
                                className="message_text">
                                {/* {message.testResult.externalNotes} */}
                            </div>
                            <div className="message_diagnosis">
                                <div className="message_pos_container test_container_font">
                                    <p id="positive-test" class="test_divider_font hidden-test">Detected</p>
                                    <div className="positive_results results_container" id="posRes"/>
                                </div>
                                <div className="message_neg_container test_container_font">
                                    <p id="negative-test" class="test_divider_font hidden-test">Not Detected</p>
                                    <div className="negative_results results_container" id="negRes"/>
                                </div>
                                <div className="message_not_container test_container_font">
                                    <p id="indeterminate-test" class="test_divider_font hidden-test">Indeterminate</p>
                                    <div className="negative_results results_container" id="notRes"/>
                                </div>
                            </div>
                        </div>
                        <div className="test-checkbox-container" id="confirmBox">
                            <input type="checkbox" className="test-checkbox" id="ackcheckbox" name="ackcheckbox"
                                   onClick={() => ackResults()}/>
                            <label id="test-checkbox-label" className="acknowledge-test-label" htmlFor="ackcheckbox">I've
                                read this and acknowledge receipt</label>
                        </div>
                        { isShowReportBtn && <div>
                            <button onClick={() => history.push(`/history?kitId=${props.message.testResult.kitId}&action=access_report`)} className="Access-report"> Download report</button>
                        </div>}

                    </div>
                </div>

                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    onClick={e => e.stopPropagation()}
                    animation={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Hey there!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Please acknowledge that you have received your results.
                        <br/>
                        - team tbd
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>Understood</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={clickedAckModal}
                    onHide={handleCloseAck}
                    backdrop="static"
                    keyboard={false}
                    onClick={e => e.stopPropagation()}
                    animation={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Body>
                        thank you for acknowledging receipt. Your response has been recorded.
                        <br/>
                        - team tbd
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseAck}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </>

        )
    }
};

export default MessageTestDetail;
