import React, { useState } from "react";
import { Auth } from "@aws-amplify/auth";
import CircularProgress from '@material-ui/core/CircularProgress';
import removeCSS from "../../util/RemoveCSS";
import { useHistory } from 'react-router-dom';
import url from "../../util/routeURL";
import config from "../../aws-exports";

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false)
    const [forgotPasswordForm, setForgotPasswordForm] = useState(true);
    const [statusComponent, setStatusComponent] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');
    const history = useHistory();

    removeCSS();
    document.body.classList.add("body_portal");

    const handleSubmit1 = () => {
        if(email !== '') {
            const configUpdate = config;
            Auth.configure(configUpdate);
            setLoading(true)
            Auth.forgotPassword(email)
                .then(data => {
                    setStatusComponent(`We sent your code to: ${email}`);
                    setLoading(false)
                    setForgotPasswordForm(false)
                })
                .catch(err => {
                    console.error(err);
                    if (err?.code === "UserNotFoundException") {
                        setStatusComponent("That TBD Health account doesn't exist. Enter a different email or reach out to us at inquiries@tbd.health");
                    } else {
                        setStatusComponent(err.message);
                    }
                    setLoading(false)
                });
        } else {
            setErrors(true)
        }
    }

    const handleSubmit2 = () => {
        const configUpdate = config;
        Auth.configure(configUpdate);
        if(email !== '' && password !== '' && code !== '') {
            setLoading(true)
            Auth.forgotPasswordSubmit(email, code, password)
                .then(data => {
                    setStatusComponent("Please continue to login");
                    setLoading(false);
                    history.push(url.login);
                })
                .catch(err => {
                    console.error(err);
                    setStatusComponent(err.message);
                    setLoading(false);
                });
        } else {
            setErrors(true)
        }
    }

    return (
        <div className="form" id="forgotpass-form">
            <div className="form-body">
                <div className="panel panel-right-border panel-form box-shadow-none" id="panel">

                    {/* {page 1} */}
                    {forgotPasswordForm &&
                        <form id="kitRegistrationForm"className="needs-validation" noValidate>
                            <span className="signon_header">Forgot Your Password?</span>
                            <div className="full-row mt-30">
                                <span id="errorSpan">{statusComponent}</span>
                                <div className="login_form_element">
                                    <label for="email" className="login-label">
                                        Enter your Email Address
                                    </label>
                                    <input
                                        type="text"
                                        id="email"
                                        className={`login_inputs ${errors && email === '' ? 'red-outline' : ''}`}
                                        required="required"
                                        placeholder="Email Address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value?.trim()?.toLowerCase())}
                                    />
                                </div>
                            </div>
                            <div id="submitButtonDiv" className="login-buttons">
                                {!loading &&
                                    <button type="button" onClick={() => handleSubmit1()} className="btn socialbutton-customizable" id="socialbutton-customizable">
                                        Submit
                                    </button>
                                }
                                {loading &&
                                    <button type="button" className="btn socialbutton-customizable" id="socialbutton-customizable">
                                        Submit
                                        <CircularProgress className="loading-button-spinner" />
                                    </button>
                                }
                            </div>
                        </form>
                    }

                    {/* page 2 */}
                    {!forgotPasswordForm &&
                        <form 
                            id="kitRegistrationForm2"
                            className="needs-validation"
                            noValidate>
                            <span className="signon_header">Confirm New Password</span>
                            <div className="full-row mg-bot-10 mt-30">
                                <h4 id="errorSpan" className="full-row">{statusComponent}</h4>
                                <label for="emailId" className="login-label">
                                    Email Address
                                </label>
                                <input
                                    type="text"
                                    id="emailId"
                                    className={`login_inputs ${errors && email === '' ? 'red-outline' : ''}`}
                                    required="required"
                                    placeholder="Email Address"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value?.trim()?.toLowerCase())}
                                />
                            </div>
                            <div className="full-row mg-bot-10">
                                <label for="password" className="login-label">
                                    New Password
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    className={`login_inputs ${errors && password === '' ? 'red-outline' : ''}`}
                                    required="required"
                                    placeholder="New Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="full-row mg-bot-10">
                                <label for="code" className="login-label">
                                    One Time Password
                                </label>
                                <input
                                    type="text"
                                    id="code"
                                    className={`login_inputs ${errors && code === '' ? 'red-outline' : ''}`}
                                    required="required"
                                    placeholder="Code"
                                    value={code}
                                    onChange={(e) => setCode(e.target.value)}
                                />
                            </div>
                            <div id="submitButtonDiv" className="full-row login-buttons">
                                {!loading &&
                                    <button onClick={() => handleSubmit2()} className="btn socialbutton-customizable"
                                        id="socialbutton-customizable">
                                        Submit
                                    </button>
                                }
                                {loading &&
                                    <button type="button" className="btn socialbutton-customizable"
                                        id="socialbutton-customizable">
                                        Submit
                                        <CircularProgress className="loading-button-spinner" />
                                    </button>
                                }
                            </div>
                        </form>
                    }
                </div>
            </div>
        </div>
    )
};

export default ForgotPassword;
