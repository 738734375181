const url = {
    registerkit: "/registerkit",
    about: "/about",
    home: "/",
    signup: "/signup",
    login: "/login",
    inbox: "/inbox",
    sent: "/sent",
    inboxItem: "/inbox/:messageID",
    history: "/history",
    setting: "/settings",
    usersetting: "/usersetting",
    appointments: "/appointments",
    forgotPword: "/forgotpassword",
    confirm: "/confirm",
    landing: "/pilot",
    nonpass: "/tbd",
    healthProfile: "/health-profile",
    manageSubscription: "/manage-subscription"
};

export default url;