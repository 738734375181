/* eslint-disable jsx-a11y/anchor-is-valid */
import {Auth} from "aws-amplify";
import React from "react";
import {Link} from "react-router-dom";
import url from "../../util/routeURL";

const MenuModal = (props) => {

    const logout = () => {
        Auth.signOut()
            .then(() => {
                localStorage.clear();
                window.location.replace("/login");
            })
            .catch(err => {
                console.error(err);
            })
    };

    return (
        <nav className="mobile-nav-wrapper medium-up--hide" style={props.openMenu ? {top: "0"} : {top: "-1400px"}} id="mobile-nav" role="navigation">
            <div className="nav-logo">
            </div>
            <ul id="MobileNav" className="mobile-nav">
                <li className="mobile-nav__item border-bottom">
                    <a href="https://www.tbd.health/" className="mobile-nav__link">
                        <span className="mobile-nav__label">Home</span>
                    </a>
                </li>
                <li className="mobile-nav__item border-bottom">
                    <a href="https://www.tbd.health/products" className="mobile-nav__link">
                        <span className="mobile-nav__label">Get a kit</span>
                    </a>
                </li>
                <li className="mobile-nav__item border-bottom">
                    <a href="https://www.tbd.health/about" className="mobile-nav__link">
                        <span className="mobile-nav__label">About</span>
                    </a>
                </li>
                <li className="mobile-nav__item border-bottom">
                    <a href="/manage-subscription" className="mobile-nav__link">
                        <span className="mobile-nav__label">Manage Subscription</span>
                    </a>
                </li>
                <li className="mobile-nav__item border-bottom">
                    <Link onClick={() => props.handleMenuClose()} className="mobile-nav__link" to={url.home}>My Account</Link>
                </li>
                <li className="mobile-nav__item border-bottom">
                    <Link onClick={() => props.handleMenuClose()} className="mobile-nav__link" to={url.inbox}>Messages</Link>
                </li>
                <li className="mobile-nav__item border-bottom">
                    <Link onClick={() => props.handleMenuClose()} className="mobile-nav__link" to={url.history}>History</Link>
                </li>
                <li className="mobile-nav__item border-bottom">
                    <Link onClick={() => props.handleMenuClose()} className="mobile-nav__link" to={url.registerkit}>Register kit</Link>
                </li>
                <li className="mobile-nav__item border-bottom">
                    <a href="https://www.tbd.health/learn" className="mobile-nav__link">
                        <span className="mobile-nav__label">UnLearn</span>
                    </a>
                </li>
                <li className="mobile-nav__item border-bottom">
                    <a href="https://www.tbd.health/pages/faqs" className="mobile-nav__link">
                        <span className="mobile-nav__label">FAQ</span>
                    </a>
                </li>
                <li className="mobile-nav__item border-bottom">
                    <a href="#" onClick={() => logout()} className="mobile-nav__link">
                        <span className="mobile-nav__label">Log Out</span>
                    </a>
                </li>
            </ul>
        </nav>
    )
};

export default MenuModal;
