import React, {useState} from "react";
import ApiService from "../../ApiService";

const ReplyMessage = (props) => {
    const [messageUUID] = useState(props.messageUUID);
    const [sendButton, setSendButton] = useState(<button id="msg_reply" className="replyButton sendButtonreply">Send <i className="fa fa-paper-plane" aria-hidden="true"></i></button>);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        document.getElementById("msg_reply").disabled = true;
        setSendButton(<i className="fas fa-spinner fa-pulse"></i>);
        
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            const content = document.getElementById("content").value;
            async function postReplyMessage() {
                try {
                    // eslint-disable-next-line no-unused-vars
                    const postMessages = await ApiService.postReplyMessage({originalMessageUUID: messageUUID, content: content});
                    window.location.reload();
                } catch(ex) {
                    console.error(ex);
                    setSendButton(<button id="msg_reply" className="replyButton sendButtonreply">Send <i className="fa fa-paper-plane" aria-hidden="true"></i></button>);
                    document.getElementById("msg_reply").disabled = false;
                }
            }
            postReplyMessage();
        }
    }

    return (
        <div className="new_msg_container">
            <form className="needs-validation new_msg_form" noValidate
                onSubmit={handleSubmit}>
                <div className="send_header msg_send">
                    {sendButton}
                    {/* <input type="submit" id="send-button" name="send-button" className="send_button"/> */}
                </div>
                <div className="inbox_container">
                    <textarea placeholder="Type your reply here" name="body" id="content" cols="30" rows="10" className="msg_text_input msg_inputs send_textarea"></textarea>
                </div>
            </form>
        </div>
    )
};

export default ReplyMessage;
