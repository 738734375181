import React from "react";
import { Modal, Button } from "react-bootstrap";

const RegisterKitModal = props => {
    
    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            keyboard={false}
            onClick={e => e.stopPropagation()}
            animation={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title>Register Your Kit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                You'll need to register your kit to get your results
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.goTo}>Register Kit Now</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default RegisterKitModal;
