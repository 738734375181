import React, {useState, useEffect} from "react";
import {Auth} from "aws-amplify";
import ApiService from "../../ApiService";
import url from "../../util/routeURL";
import {useHistory} from "react-router-dom";
import removeCSS from "../../util/RemoveCSS";
import config from "../../aws-exports";

const ConfirmForm = (props) => {
    const configUpdate = config;
    Auth.configure(configUpdate);
    const [page, setPage] = useState(1);
    const [email, setEmail] = useState(window.location.href.split("email=")[1]);
    const [errors, setErrors] = useState(props.error);
    const [confirmationCode, setConfirmationCode] = useState("");
    const [placeHolder, setPlaceHolder] = useState("Email Address");
    const history = useHistory();
    
    removeCSS();
    document.body.classList.add("body_login");

    const handleConfirmation = (event) => {
        const form = event.currentTarget;

        setErrors("");
        if (form.checkValidity() === false) {
            setErrors("Please make sure that a code value is entered.");
        } else {

            Auth.confirmSignUp(email, confirmationCode)
                .then(() => {
                    setPage(3);
                    setErrors("Thank you for confirming your account. Please log in once more to complete the process.");
                    sendWelcomeMessage();
                    return false;
                })
                .catch(err => {
                    setErrors("Incorrect code value, Please try again");
                    return false;
                })
        }
    }

    const sendWelcomeMessage = () => {
        async function postWelcomeEmail() {
            try {
                // eslint-disable-next-line no-unused-vars
                const postWelcomeEmail = await ApiService.postWelcomeEmail({email: email});
            } catch(ex) {
                console.error(ex);
            }
        }
        postWelcomeEmail();
    }


    const loginButton = () => {
        history.push(url.login);
    };

    const resendCode = (event) => {
        Auth.resendSignUp(email)
            .then(() => {
                setErrors("A new code has been resent. Please check your email for the new code.");
                setPage(1)
            })
            .catch(err => {
                event.stopPropagation();
                setErrors(err.message);
            })
    };

    const resendCodeButton = () => {
        setErrors("")
        setPage(2);
    };

    useEffect(() => {
        if (window.location.href.indexOf("email=") > -1) {
            setPlaceHolder(window.location.href.split("email=")[1]);
        } else {
            setPlaceHolder("Email Address");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.href.split("email=")[1]]);

    return (
        <div className="form" id="login-form">
            <img className="traffic-junky" id="1000212661_cpa_testing" src="https://ads.trafficjunky.net/tj_ads_pt?a=1000212661&member_id=1002578341&cb=
[RANDOM_NUMBER]&cti=[TRANSACTION_UNIQ_ID]&ctv=[VALUE_OF_THE_TRANSACTION]&ctd=[TRANSACTION_DESCRIPTION]"
                 width="1" height="1" border="0" alt="traffic junky"/>
            {page === 1 ?
                <div className="form-body" id="panel">
                    <h1 class="signon_header">Confirm Account</h1>
                    {email && email.length ? <h4 class="confirm_subheader">One-Time Confirmation code has been sent to your email account.</h4>: ""}
                    <form id="signinForm" className="needs-validation login_form"
                        class="signup_form"
                        noValidate>
                        <span id="errorSpan">{errors}</span>
                        <div className="full-row mt-30">
                            <div className="login_form_element">
                                <label for="signin-email" className="login-label">
                                    Enter your Email Address
                                </label>
                                <input
                                    type="text"
                                    id="signin-email"
                                    className={(email === "" && errors) || (email === undefined && errors) ? `login_inputs red-outline` : `login_inputs`}
                                    placeholder={placeHolder}
                                    required="required"
                                    value={email}
                                    onInput={(e) => setEmail(e.target.value?.trim()?.toLowerCase())}
                                />
                            </div>
                        </div>
                        <div className="full-row">
                            <div className="login_form_element">
                                <label for="code" className="login-label">
                                    Code
                                </label>
                                <input
                                    type="text"
                                    id="code"
                                    className={confirmationCode === "" && errors ? `login_inputs red-outline` : `login_inputs`}
                                    placeholder="Code"
                                    required="required"
                                    value={confirmationCode}
                                    onInput={(e) => setConfirmationCode(e.target.value)}
                                />
                            </div>
                        </div>

                        <div class="full-row mt-30">
                            <span id="resendCodeSpan" className="countdown-content">
                                Need the code to be resent? 
                                <span>
                                    {/* Resend Code */}
                                    <button type="button" className="button-destructure" onClick={() => resendCodeButton()}>Resend Code</button>
                                </span>
                            </span> 
                            &nbsp; &nbsp;
                            <button type="button"  onClick={handleConfirmation} className="btn socialbutton-customizable" id="socialbutton-customizable">
                                Submit
                            </button>
                        </div>
                        {/* <div className="full-row login-buttons">
                            <button type="button"  onClick={handleConfirmation} className="btn socialbutton-customizable" id="socialbutton-customizable">
                                Submit
                            </button>
                        </div> */}
                    </form>
                </div>
            : page === 2 ? 
                <form id="resendCode" className="resend-code-body">
                    <h1 class="confirm_header">Resend Code</h1>
                    <span id="errorSpan">{errors}</span>
                    <div className="full-row">
                        <div className="login_form_element">
                            <label for="signin-email" className="login-label">
                                    Email Address
                            </label>
                            <input
                                type="text"
                                id="signin-email"
                                className={(email === "" && errors) || (email === undefined && errors) ? `login_inputs red-outline` : `login_inputs`}
                                placeholder={placeHolder}
                                required="required"
                                value={email}
                                onInput={(e) => setEmail(e.target.value?.trim()?.toLowerCase())}
                            />
                        </div>
                        <button onClick={resendCode} type="button" className="button-destructure alt-socialbutton-customizable">Send</button>
                    </div>
                </form>
            : 
                <div id="confirmation" className="confirmation_body">
                    <div className="util_text">
                        Account has been successfully created!
                        <br/>
                        Please continue to Login page.
                    </div>
                    <button className="button-destructure socialbutton-customizable" onClick={loginButton}>Login</button>
                </div>
            }

        </div>
    )
};

export default ConfirmForm;
