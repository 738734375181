
import WA from './WA.json'
import FL from './FL.json'
import NV from './NV.json'
import CT from './CT.json'
import AZ from './AZ.json'
import MA from './MA.json'
import NY from './NY.json'
import AK from './AK.json'
import AL from './AL.json'
import AR from './AR.json'
import AS from './AS.json'
import CA from './CA.json'
import DC from './DC.json'
import DE from './DE.json'
import GA from './GA.json'
import GU from './GU.json'
import HI from './HI.json'
import IA from './IA.json'
import ID from './ID.json'
import IL from './IL.json'
import IN from './IN.json'
import KS from './KS.json'
import KY from './KY.json'
import LA from './LA.json'
import MD from './MD.json'
import ME from './ME.json'
import MI from './MI.json'
import MN from './MN.json'
import MO from './MO.json'
import MP from './MP.json'
import MS from './MS.json'
import MT from './MT.json'
import NC from './NC.json'
import ND from './ND.json'
import NE from './NE.json'
import NH from './NH.json'
import NJ from './NJ.json'
import NM from './NM.json'
import OH from './OH.json'
import OK from './OK.json'
import OR from './OR.json'
import PA from './PA.json'
import RI from './RI.json'
import SC from './SC.json'
import SD from './SD.json'
import TN from './TN.json'
import TX from './TX.json'
import UT from './UT.json'
import VA from './VA.json'
import VI from './VI.json'
import VT from './VT.json'
import WI from './WI.json'
import WV from './WV.json'
import WY from './WY.json'
import NoZip from './_.json'

// download from https://worldpopulationreview.com/zips/arizona

const States = [
    {code: 'AK', active: true, zipcode: AK},
    {code: 'AL', active: true, zipcode: AL},
    {code: 'AR', active: true, zipcode: AR},
    {code: 'AZ', active: true, zipcode: AZ},
    {code: 'CA', active: true, zipcode: CA},
    {code: 'CO', active: true, zipcode: NoZip},
    {code: 'CT', active: true, zipcode: CT},
    {code: 'DC', active: true, zipcode: DC},
    {code: 'DE', active: true, zipcode: DE},
    {code: 'FL', active: true, zipcode: FL},
    {code: 'GA', active: true, zipcode: GA},
    {code: 'HI', active: true, zipcode: HI},
    {code: 'IA', active: true, zipcode: IA},
    {code: 'ID', active: true, zipcode: ID},
    {code: 'IL', active: true, zipcode: IL},
    {code: 'IN', active: true, zipcode: IN},
    {code: 'KS', active: true, zipcode: KS},
    {code: 'KY', active: true, zipcode: KY},
    {code: 'LA', active: true, zipcode: LA},
    {code: 'MA', active: true, zipcode: MA},
    {code: 'MD', active: true, zipcode: MD},
    {code: 'ME', active: true, zipcode: ME},
    {code: 'MI', active: true, zipcode: MI},
    {code: 'MN', active: true, zipcode: MN},
    {code: 'MO', active: true, zipcode: MO},
    {code: 'MS', active: true, zipcode: MS},
    {code: 'MT', active: true, zipcode: MT},
    {code: 'NC', active: true, zipcode: NC},
    {code: 'ND', active: true, zipcode: ND},
    {code: 'NE', active: true, zipcode: NE},
    {code: 'NH', active: true, zipcode: NH},
    {code: 'NJ', active: true, zipcode: NJ},
    {code: 'NM', active: true, zipcode: NM},
    {code: 'NV', active: true, zipcode: NV},
    {code: 'NY', active: true, zipcode: NY},
    {code: 'OH', active: true, zipcode: OH},
    {code: 'OK', active: true, zipcode: OK},
    {code: 'OR', active: true, zipcode: OR},
    {code: 'PA', active: true, zipcode: PA},
    {code: 'RI', active: true, zipcode: RI},
    {code: 'SC', active: true, zipcode: SC},
    {code: 'SD', active: true, zipcode: SD},
    {code: 'TN', active: true, zipcode: TN},
    {code: 'TX', active: true, zipcode: TX},
    { code : 'UT', active:true, zipcode: UT},
    { code : 'VA', active:true, zipcode: VA},
    { code : 'VT', active:true, zipcode: VT},
    { code : 'WA', active:true, zipcode: WA},
    { code : 'WI', active:true, zipcode: WI},
    { code : 'WV', active:true, zipcode: WV},
    { code : 'WY', active:true, zipcode: WY},
]

export default States;