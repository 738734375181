import React, {useState} from "react";
import ReplyMessage from "./ReplyMessage";
import moment from "moment";
import linkedText from "../../util/linked-text";

const AppointmentRequest = (props) => {
    const {messageUUID, message, sentMessage} = props;
    const [replyMessageComponent, setReplyMessageComponent] = useState(null);

    function reply() {
        setReplyMessageComponent(<ReplyMessage messageUUID={messageUUID}/>);
    }
    if (!message) {
        return (<div className="open_message">Fetching Message</div>)
    } else {
        const formattedText = linkedText(message.content)
        return (
            <div className="open_message" id="open_message">
                <div className="message_container" id="open_message">
                    {replyMessageComponent}
                    <div className="message_header">
                        <div className="from_header_el header-from">{message.from}</div>
                        <div className="header-subject">{message.subject}</div>
                        <div
                            className="header-date">{moment(message.createdDateTime).format("ddd MMM D, YYYY LT")}</div>
                        {
                            sentMessage !== true ?
                                <button onClick={reply} id="msg_reply" className="replyButton">Reply <i class="fas fa-reply"></i></button>
                                : null
                        }
                    </div>
                </div>
                <div className="message-margin">
                    <div className="message_content">
                        <div className="message_text" dangerouslySetInnerHTML={{ __html: formattedText}}>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AppointmentRequest;
