import create from 'zustand';
import { persist } from 'zustand/middleware';

const useUserStore = create(
    persist((set, get) => ({
        patient: null,
        isAuth: false,
        setPatient: (patient) => set({ patient }),
        setIsAuth: (isAuth) => set({ isAuth }),
    }), {
        name: 'tbd-pu',
        getStorage: () => localStorage,
    })
);

export default useUserStore;
