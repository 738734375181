/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useRef, useState} from "react";
import {Auth} from "aws-amplify";
import {Link, useHistory, withRouter} from "react-router-dom";
import url from "../../util/routeURL";
import ApiService from "../../ApiService";
import {eligibleState, getStates, validZipCode} from '../../config/config';
import removeCSS from "../../util/RemoveCSS";
import './style.css';
import PassMemberTermsModal from './PassMemberTermsModal';
import {getPreparedPhoneNumber, isValidDate, validPhoneNumber} from '../../util/UtilFunctions';
import {DateTime} from "luxon";

const passwordValidator = require("password-validator");
const schema = new passwordValidator();

const states = getStates();

schema
    .is()
    .min(8)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits()
    .has()
    .symbols();

const SignUpForm = () => {
    const [page, setPage] = useState(1);
    const [fname, setFname] = useState("");
    const [lname, setLname] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [streetAddress, setStreetAddress] = useState("");
    const [streetAddress2, setStreetAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [allowedState, setAllowedState] = useState(true)
    const [zip, setZip] = useState("");
    const [validZip, setValidZip] = useState("")
    const [dob, setDob] = useState("");
    const [tooYoung, setTooYoung] = useState(false);
    const [gender, setGender] = useState("");
    const [sexAtBirth, setSexAtBirth] = useState("");
    const [email, setEmail] = useState("");
    const [disableEmail, setDisableEmail] = useState(false);
    const [errors, setErrors] = useState([]);
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [allergies, setAllergies] = useState("");
    const [passwordError1, setPasswordError1] = useState(null);
    const [passwordError2, setPasswordError2] = useState(null);
    const [dupeEmail, setDupeEmail] = useState(false);
    const [validateEmail, setValidateEmail] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [privacyChecked, setPrivacyChecked] = useState(false);
    const [telemedicineChecked, setTelemedicineChecked] = useState(false);
    const [race, setRaceValue] = useState('Unknown');
    const [ethnicity, setEthnicityValue] = useState('Unknown');
    const [symptoms, setSymptomsValue] = useState(null);
    const [medications, setMedicationsValue] = useState(null);
    const [pronoun, setPronounValue] = useState('');
    const [isPassMember, setPassMemberStatus] = useState(false);
    const [passMemberAck, setPassmemberAck] = useState(false);
    const [shareToPass, setShareToPass] = useState(false);
    const [preferredName, setPreferredName] = useState('');
    const errorRef = useRef(null);
    const ethnicityList = [
        {label: 'True', value: 'Hispanic or Latino', key: 'hispani'},
        {label: 'False', value: 'not Hispanic or Latino', key: 'not-hispani'},
        {label: 'Decline to State', value: 'Unknown', key: 'unknown'},
    ];

    const setDefaultEmail = () => {
        const params = new URLSearchParams(window.location.search);
        if(params.has('email')){
            const emailId = params.get('email');
            if(emailId && emailId.length) {
                setDisableEmail(true);
                setEmail(emailId?.trim()?.toLowerCase());
            }
        }
    }

    useEffect(() => {
        console.log('signup');
        const loginUrl = `${process.env.REACT_APP_MAIN_SITE_BASE_URL}/sign-up?redirect=patient-portal`;
        window.open(loginUrl, '_self');
    }, [])

    useEffect(() => {
        if(!errors?.length) return;
        errorRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center'});
    }, [errors]);

    useEffect(() => {
        const loginUrl = `${process.env.REACT_APP_MAIN_SITE_BASE_URL}/sign-up?redirect=patient-portal`;
        window.open(loginUrl, '_self');
    }, [])

    useEffect(() => {
        setDefaultEmail();
    }, []);

    const ethnicityFormGroup = [];
    ethnicityList.forEach(et => {
        ethnicityFormGroup.push(
            <label for={et.key} class="radio-input">
                <input type="radio" id={et.key} name="ethnicityInput"
                       checked={et.value === ethnicity}
                       value={et.value} onChange={(e) => setEthnicityValue(e.target.value)}/>
                <span>{et.label}</span>
            </label>
        );
    });
    const raceList = [
        {label: 'American Indian or Alaska Native', key: 'alaska', value: 'American Indian or Alaska Native'},
        {label: 'Asian', value: 'Asian', key: 'asian'},
        {
            label: 'Native Hawaiian or Other Pacific Islander',
            key: 'Hawaiian',
            value: 'Native Hawaiian or Other Pacific Islander'
        },
        {label: 'Black or African-American', key: 'africa', value: 'Black or African-American'},
        {label: 'White', value: 'White', key: 'white'},
        {label: 'Other', value: 'Other', key: 'other'},
        {label: 'Decline to State', value: 'Unknown', key: 'unknown', checked: true}
    ];
    const raceFormGroup = [];
    raceList.forEach(et => {
        raceFormGroup.push(
            <label for={et.key} class="radio-input">
                <input type="radio" id={et.key} name="raceInput"
                       checked={et.value === race}
                       value={et.value} onChange={(e) => setRaceValue(e.target.value)}/>
                <span>{et.label}</span>
            </label>
        );
    });

    const history = useHistory();

    removeCSS();
    document.body.classList.add("body_login");

    //look for duplicate email
    useEffect(() => {

        if (validateEmail) {
            setValidateEmail(false)

            async function getDuplicateEmailCheck() {
                try {
                    const getDuplicateEmailCheck = await ApiService.getDuplicateEmailCheck(encodeURIComponent(email));
                    setDupeEmail(getDuplicateEmailCheck.data);
                } catch (ex) {
                    console.error(ex);
                }
            }

            getDuplicateEmailCheck();
        }
    }, [validateEmail, email]);

    const backButton = () => {
        if (page > 1 && page <= 4) {
            setPage(page - 1);
        }
    };

    const handleCloseAck = (status) => {
        setShareToPass(status);
        setPassmemberAck(false)
    };

    const formatPasswordValidateError = (err) => {
        if (err) {
            return "Passwords must be 8-12 characters and include at least one uppercase, at least one special character (!@#$%^&*), and at least one number.";
        }
    };

    const handleSubmitPage1 = (event) => {
        setErrors([]);
        let err = [];


        const validationRulesErrors = schema.validate(password, {list: true});

        if (validationRulesErrors.length === 0) {
            setPasswordError1(false);
        }
        if (password === password2) {
            setPasswordError2(false);
        }

        if(!pronoun?.trim() || !fname?.trim() || !lname?.trim() || !email?.trim()){
            err.push("Please enter all required fields.");
        }  else if (dupeEmail) {
            err.push("Email address already in system. Please choose a different email address or login");
            if (validationRulesErrors.length > 0) {
                setPasswordError1(true);
            } else if (password !== password2) {
                setPasswordError2(true);
            }
        } else if (validationRulesErrors.length > 0) {
            err.push(formatPasswordValidateError(validationRulesErrors));
            setPasswordError1(true);
            if (password !== password2 || password2 === "") {
                setPasswordError2(true);
            }
        } else if (password !== password2) {
            err.push("The two passwords do not match.");
            setPasswordError2(true);
        }
        if(err.length > 0){
            setErrors(err);
            return;
        }
        setPage(2)
    }

    const handleStateChange = (event) => {
        setAllowedState(eligibleState(event.target.value))
        setState(event.target.value);
    }

    const handleZipChange = (event) => {
        //setValidZip(validZipCode(state, event.target.value));
        setValidZip(true);
        setZip(event.target.value.trim())
    }

    const isTooYoung = () => {
        if(!dob) return false;
        const diffYrs = DateTime.now().diff(DateTime.fromJSDate(dob), 'years').years;
        return Math.abs(diffYrs) <= 15;
    }

    const handleSubmitPage2 = (event) => {
        setErrors([]);
        let err = [];

        if (!streetAddress?.trim() || !city?.trim() || !state?.trim() || !zip || !phoneNumber?.trim() || !dob){
            err.push("Please enter all required fields.");
        } else if (state === 'NY') {
            err.push("Sorry, our service not currently providing in your state");
        } else if (!validZip) {
            err.push("Please double check your zip code");
        } else if (!validPhoneNumber(phoneNumber)) {
            err.push("Invalid phone number format");
        }

        if(err.length > 0){
            setErrors(err);
            return;
        }
        setPage(3);
    }

    const handleSubmitPage3 = () => {
        setErrors([]);
        setPage(4);
    }


    const handleSubmitFinal = (event) => {
        setErrors([]);
        event.preventDefault();
        let address = {};
        address['locality'] = city;
        address['region'] = state;
        address['postal_code'] = zip;
        address['street_address'] = streetAddress;
        address['street_address2'] = streetAddress2;

        const prepPhoneNum = getPreparedPhoneNumber(phoneNumber);
        Auth.signUp({
            username: email,
            password,
            attributes: {
                email,
                phone_number: prepPhoneNum?.startsWith('+1') ? prepPhoneNum : "+1" + prepPhoneNum,
            }})
            .then(async () => {
                await ApiService.postUserHealthData(
                    {
                        email: email,
                        phoneNumber: phoneNumber,
                        givenName: fname,
                        familyName: lname,
                        gender,
                        sexAtBirth,
                        birthDate: dob,
                        address: address,
                        name: fname + " " + lname,
                        preferred_username: email,
                        'allergies': !allergies ? 'none' : allergies,
                        'ethnicity': ethnicity,
                        'race': race,
                        'symptoms': !symptoms ? 'none' : symptoms,
                        'medications': !medications ? 'none' : medications,
                        'pronoun': pronoun,
                        'isPassMember': isPassMember.toString(),
                        'isShareWIthPass': shareToPass.toString(),
                        'preferredName': preferredName
                    }
                );

                setEmail(email?.trim()?.toLowerCase());
                setPage(5)
            })
            .catch(err => {
                event.stopPropagation();
                errors.push(err?.message ?? 'Request failed, please try again');
                return false;
            })

    }

    return (
        <div className="form" id="signup-form">
            <div className="form-body panel-form" id="panel">
                <div className="signon_header_container">
                    <h1 className="signon_header">Sign Up</h1>
                </div>
                {errors?.map((err, index) => {
                    return (
                        <div id="errorDiv" className="signup-errors" ref={index === 0 ? errorRef : null} key={index}>
                            {err}
                        </div>
                    );
                })}
                <br/>
                {page === 1 ?
                    <form id="signupFormPage1" className="full-form-group" noValidate>
                        <div className="subrow" id="sub-row-page1">
                            <div className="form-group">
                                <label for="pronoun" className="signup-label">Addressed as</label>
                                <select id="pronoun" value={pronoun}
                                        className={pronoun === "" && errors?.length > 0  ? `login_inputs red-outline` : `login_inputs`}
                                        onChange={(e) => setPronounValue(e.target.value)}>
                                    <option value=""></option>
                                    <option value="he/him">He/Him</option>
                                    <option value="she/her">She/Her</option>
                                    <option value="they/them">They/Them</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label for="fname" className="signup-label fw-300">Legal First Name</label>
                                <input
                                    type="text"
                                    id="fname"
                                    className={fname === "" && errors?.length > 0 ? `login_inputs red-outline` : `login_inputs`}
                                    required="required"
                                    value={fname}
                                    onInput={(e) => setFname(e.target.value.trim())}
                                />
                            </div>
                            <div className="form-group">
                                <label for="lname" className="signup-label fw-300">Legal Last Name</label>
                                <input
                                    type="text"
                                    id="lname"
                                    className={lname === "" && errors?.length > 0 ? `login_inputs red-outline` : `login_inputs`}
                                    required
                                    value={lname}
                                    onInput={(e) => setLname(e.target.value.trim())}
                                />
                            </div>
                            <div className="form-group">
                                <label for="preferred_name" className="signup-label fw-300">Preferred Name</label>
                                <input
                                    type="text"
                                    id="preferred_name"
                                    className="login_inputs"
                                    value={preferredName}
                                    onInput={(e) => setPreferredName(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label for="signup-email" className="signup-label fw-300">Email address</label>
                                <input
                                    type="text"
                                    id="signup-email"
                                    className={(email === "" && errors?.length > 0) || dupeEmail ? `login_inputs red-outline` : `login_inputs`}
                                    required
                                    value={email}
                                    onInput={(e) => setEmail(e.target.value?.trim()?.toLowerCase())}
                                    disabled={disableEmail}
                                    onBlur={() => setValidateEmail(true)}
                                />
                            </div>
                            <div className="form-group">
                                <label for="password" className="signup-label">Password</label>
                                <div className="input_icons">
                                    <input
                                        type="password"
                                        id="signup-password"
                                        className={`${passwordError1 ? 'login_inputs pword_input red-outline' : 'login_inputs pword_input'}`}
                                        required
                                        value={password}
                                        onInput={(e) => setPassword(e.target.value.trim())}
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label for="signup-password2" className="signup-label fw-300">Confirm password</label>
                                <div className="input_icons">
                                    <input
                                        type="password"
                                        id="signup-password2"
                                        className={`${passwordError2 ? 'login_inputs pword_input red-outline' : 'login_inputs pword_input'}`}
                                        required
                                        value={password2}
                                        onInput={(e) => setPassword2(e.target.value.trim())}
                                    />
                                </div>
                            </div>

                            <div className="form-group-password-hint">
                                <small id="passwordHelpBlock" className="form-text text-muted">Your password must be
                                    8-20
                                    characters. You must include Numbers, Symbols, Capital, and Lower Case
                                    Characters </small>
                            </div>
                            <div className="button_container_column">
                                <button id="nextButton" type="button" onClick={handleSubmitPage1}
                                        className="socialbutton-customizable">Next
                                </button>
                                <br/><br/>
                                <small className="signup-label">Already have an
                                    account? <Link
                                        to={url.login}>Log in
                                    </Link></small>
                            </div>
                        </div>
                    </form>
                    : page === 2 ?
                        <form id="signupFormPage2" className="full-form-group" noValidate>
                            <div className="subrow" id="sub-row-page2">
                                <div className="form-group">
                                    <label for="dob" className="signup-label">Date of Birth</label>
                                    <input
                                        type="date"
                                        id="dob"
                                        className={(dob === "" && errors?.length > 0) || (tooYoung && errors?.length > 0) || (isNaN(new Date(dob).getTime()) && errors?.length > 0) ? `login_inputs red-outline` : `login_inputs`}
                                        placeholder="MM/DD/YYYY"
                                        required
                                        value={dob}
                                        onInput={(e) => setDob(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="gender" className="signup-label">Which genitals do you have?</label>
                                    <select id="gender" value={gender}
                                            className={(gender === "" && errors?.length > 0) ? `login_inputs red-outline` : `login_inputs`}
                                            onChange={(e) => setGender(e.target.value)}>
                                        <option value=""></option>
                                        <option value="male">Penis</option>
                                        <option value="female">Vagina</option>
                                        <option value="intersex">Intersex</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label for="sexAtBirth" className="signup-label">Sex Assigned at Birth?</label>
                                    <select id="sexAtBirth" value={sexAtBirth}
                                            className={(sexAtBirth === "" && errors?.length > 0) ? `login_inputs red-outline` : `login_inputs`}
                                            onChange={(e) => setSexAtBirth(e.target.value)}>
                                        <option value=""></option>
                                        <option value="male">Assigned male at birth</option>
                                        <option value="female">Assigned female at birth</option>
                                        <option value="unknown">Unknown</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label for="street_address" className="signup-label fw-300">Street Address</label>
                                    <input
                                        type="text"
                                        id="street_address"
                                        className={streetAddress === "" && errors?.length > 0 ? `login_inputs red-outline` : `login_inputs`}
                                        required
                                        value={streetAddress}
                                        onInput={(e) => setStreetAddress(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="street_address2" className="signup-label">Address Line 2</label>
                                    <input
                                        type="text"
                                        id="street_address2"
                                        className="login_inputs"
                                        required
                                        value={streetAddress2}
                                        onInput={(e) => setStreetAddress2(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="city" className="signup-label fw-300">City</label>
                                    <input
                                        type="text"
                                        id="city"
                                        className={city === "" && errors?.length > 0 ? `login_inputs red-outline` : `login_inputs`}
                                        required
                                        value={city}
                                        onInput={(e) => setCity(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="state" className="signup-label fw-300">State</label>
                                    <select id="state" value={state} name="state"
                                            className={(state === "" && errors?.length > 0) || !allowedState ? `login_inputs red-outline` : `login_inputs`}
                                            onChange={(e) => handleStateChange(e)}>
                                        <option value="" className="login_inputs"></option>
                                        {states.map(state => <option value={state}>{state}</option>)}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label for="zip" className="signup-label fw-300">Zip Code</label>
                                    <input
                                        type="text"
                                        id="zip"
                                        className={(zip === "" && errors?.length > 0) || (!validZip && errors?.length > 0) ? `login_inputs red-outline` : `login_inputs`}
                                        required
                                        value={zip}
                                        onChange={(e) => handleZipChange(e)}
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="phoneNumber" className="signup-label fw-300">Phone Number</label>
                                    <input
                                        type="text"
                                        id="phoneNumber"
                                        className={phoneNumber === "" && errors?.length > 0 ? `login_inputs red-outline` : `login_inputs`}
                                        placeholder="(XXX) XXX-XXXX"
                                        required
                                        value={phoneNumber}
                                        onInput={(e) => setPhoneNumber(e.target.value)}
                                    />
                                </div>
                                <div className="button_container">
                                    <div id="backButton" className="alt-socialbutton-customizable"
                                         onClick={backButton}>Back
                                    </div>
                                    <button id="nextButton" type="button" onClick={handleSubmitPage2}
                                            className="socialbutton-customizable">Next
                                    </button>
                                </div>
                            </div>
                        </form>
                        : page === 3 ?
                            <form id="signupFormPage3" className="full-form-group" noValidate>
                                <div className="subrow" id="sub-row-page3">
                                    <div className="form-group-text-area">
                                        <label for="allergies" className="signup-label">Allergies</label>
                                        <textarea
                                            id="allergies"
                                            className="signup_text_box"
                                            placeholder=""
                                            required
                                            onInput={(e) => setAllergies(e.target.value)}
                                        >
                                    {allergies}
                                </textarea>
                                    </div>
                                    <div className="form-group">
                                        <label for="symptoms" className="signup-label">Symptoms</label>
                                        <br></br>
                                        <textarea
                                            className="signup_text_box"
                                            required
                                            id="symptoms"
                                            onInput={(e) => setSymptomsValue(e.target.value)}
                                        >
                                    {symptoms}
                                </textarea>
                                    </div>
                                    <div className="form-group">
                                        <label for="medications" className="signup-label">Medications</label>
                                        <textarea
                                            className="signup_text_box"
                                            required
                                            id="medications"
                                            onInput={(e) => setMedicationsValue(e.target.value)}
                                        >
                                    {medications}
                                </textarea>
                                    </div>
                                    <div className="form-group">
                                        <div className="signup-label">Are you of Hispanic or Latinx origin?</div>
                                        {ethnicityFormGroup}
                                    </div>
                                    <div className="form-group">
                                        <div className="signup-label">Do you identify with any of the following
                                            groups?
                                        </div>
                                        {raceFormGroup}
                                    </div>
                                    <div className="form-group">
                                        <div className="signup-label">Are you a member of PASS?</div>
                                        <label for="passYes" class="radio-input">
                                            <input id="passYes" type="radio" name="passFormInput" value="true"
                                                   checked={isPassMember === true} onChange={() => {
                                                setPassMemberStatus(true);
                                                setPassmemberAck(true);
                                            }}/>
                                            &nbsp; Yes
                                        </label>
                                        <label for="passNo" class="radio-input">
                                            <input id="passNo" type="radio" name="passFormInput" value="false"
                                                   checked={isPassMember === false}
                                                   onChange={() => setPassMemberStatus(false)}/>
                                            &nbsp; No
                                        </label>
                                    </div>
                                    { isPassMember === true ?
                                         <p className="formHint">“Please ensure that the email address you have used to create your TBD account matches the email address on your PASS account or your results will not display in PASS.”</p> : ""
                                    }
                                    <div className="button_container">
                                        <div id="backButton" className="alt-socialbutton-customizable"
                                             onClick={backButton}>Back
                                        </div>
                                        <button id="nextButton" type="button" onClick={handleSubmitPage3}
                                                className="socialbutton-customizable">Next
                                        </button>
                                    </div>
                                </div>
                            </form>
                            : page === 4 ?
                                <form id="signupFormPage4" className="full-form-group" noValidate
                                      onSubmit={handleSubmitFinal}>
                                    <p className="checkText">Check here to indicate that you have read and agree to the
                                        following
                                        terms and conditions:</p>
                                    <div className="form-group-submit-area">
                                        <div className="form-checkbox-group">
                                            <div className="form-checkbox-flex">
                                                <input type="checkbox" id="termsCheckbox" name="termsCheckbox"
                                                       className="login_checkbox" checked={termsChecked}
                                                       onChange={() => setTermsChecked(!termsChecked)}/>
                                                <label className="textbox-labels" for="termsCheckbox"
                                                       id="termsCheckboxId"><a
                                                    href={"https://www.tbd.health/terms"}
                                                    id="termsLink"
                                                    className="textbox-labels-text" target="_blank">Terms of Service</a></label>
                                            </div>
                                            <div className="form-checkbox-flex">
                                                <input type="checkbox" id="privacyCheckbox" name="privacyCheckbox"
                                                       className="login_checkbox" checked={privacyChecked}
                                                       onChange={() => setPrivacyChecked(!privacyChecked)}/>
                                                <label className="textbox-labels" for="privacyCheckbox"
                                                       id="privacyCheckboxId"><a
                                                    href={"https://www.tbd.health/privacy-policy"}
                                                    id="privacyLink"
                                                    className="textbox-labels-text" target="_blank">Privacy
                                                    Policy</a></label>

                                            </div>
                                            <div className="form-checkbox-flex">
                                                <input type="checkbox" id="telemedicineCheckbox"
                                                       name="telemedicineCheckbox"
                                                       className="login_checkbox" checked={telemedicineChecked}
                                                       onChange={() => setTelemedicineChecked(!telemedicineChecked)}/>
                                                <label className="textbox-labels" for="telemedicineCheckbox"
                                                       id="telemedicineCheckboxId"><a
                                                    href={"https://www.tbd.health/consent-to-telehealth"}
                                                    id="telemedicineLink" className="textbox-labels-text"
                                                    target="_blank">Telehealth
                                                    Consent</a></label>

                                            </div>
                                        </div>
                                        <div className="button_container">
                                            <div id="backButton" className="alt-socialbutton-customizable"
                                                 onClick={backButton}>Back
                                            </div>
                                            {termsChecked && privacyChecked && telemedicineChecked ?
                                                <button type="submit" className="socialbutton-customizable">Sign up
                                                </button>
                                                :
                                                <button type="submit" className="socialbutton-customizable-disabled"
                                                        disabled>Sign up
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </form>
                                :
                                <div id="signupFormPage4" className="signupFormPage4">
                                    <p className="signup_confirm">Your TBD account was successfully created. Please
                                        check your email for the
                                        confirmation code and then log in to enter the code.</p>
                                    <button onClick={() => history.push(`/confirm?email=${email}`)} id="countdown"
                                            className="countdown_font socialbutton-customizable"> OK <i
                                        className="fas fa-check" aria-hidden="true"/></button>
                                </div>
                }
            </div>
            <PassMemberTermsModal show={passMemberAck} handleClose={handleCloseAck}/>
        </div>
    )
};

export default withRouter(SignUpForm);
