import React, { useState } from "react";
import ApiService from "../../ApiService";
import { Modal } from "react-bootstrap";
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useEffect } from "react";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ShareModal = props => {
    const [myResultsChecked, setMyResultsChecked] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [recipientList, setRecipientList] = useState([{recipientName: '', contactInfo: ''}])
    const [canSendResults, setCanSendResults] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [page, setPage] = useState(1);
    const [profilePic, setProfilePic] = useState('https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png');

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    
    const handleSnackClose = () => {
        setSnackOpen(false);
    };

    const shareInfo = () => {
        async function postUserSharingRecipients() {
            try {
                // eslint-disable-next-line no-unused-vars
                const postUserSharingRecipients = await ApiService.postUserSharingRecipients(props.kitId, {"recipients": recipientList});
                setSnackOpen(true);
                setRecipientList([{recipientName: '', contactInfo: ''}]);
                setMyResultsChecked(false);
            } catch(ex) {
                console.error(ex);
            }
        }
        postUserSharingRecipients();
    }

    const handleAddRecipient = () => {
        setRecipientList([...recipientList, {recipientName: '', contactInfo: ''}])
    }

    const handleChange = (e, rowIndex, input) => {
        const recipientUpdate = recipientList.map((recipient, index) => {
            if(rowIndex === index) {
                if(input === 'name') {
                    recipient.recipientName = e.target.value;
                } else {
                    recipient.contactInfo = e.target.value;
                }
            }
            return recipient
        })
        setRecipientList(recipientUpdate)
    }

    const handleDelete = (rowIndex) => {
        // eslint-disable-next-line array-callback-return
        const recipientDelete = recipientList.filter((recipient, index) => {
            if(rowIndex !== index) {
                return recipient;
            }
        })
        setRecipientList(recipientDelete)
    }

    useEffect(() => {
        async function getUserPic() {
            try {
                const getUserPic = await ApiService.getUserPic();
                setProfilePic(getUserPic.data)
            } catch(ex) {
                console.error(ex);
            }
        }
        if(props.customerIdState){
            getUserPic();
        }
    }, [props.customerIdState])

    useEffect(() => {
        let filledOutRecipients = 0;
        // eslint-disable-next-line array-callback-return
        recipientList.map(recipient => {
            const recipients = recipientList.length;
            if(recipient.recipientName !== '' && recipient.contactInfo !== '') {
                filledOutRecipients++;
            }
            if(recipients === filledOutRecipients) {
                setCanSendResults(true);
            } else {
                setCanSendResults(false);
            }
        })
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [recipientList])

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            keyboard={false}
            onClick={e => e.stopPropagation()}
            animation={false}
            size="lg"
            centered
        >
            <div className="share-modal-wrap">
                {width < 769 ?
                <>
                    {page === 1 ?
                        <>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <h2>Results</h2>
                                    <h4>This is what your recipient(s) will see</h4>
                                </Modal.Title>
                            </Modal.Header>
                            <div className="share-data-wrap flex-desktop">
                                <div className="share-data flex align-items-center">
                                    <div className="share-img-upload mr-10">
                                        <img src={profilePic} alt="" width="100" />
                                    </div>
                                    <div className="share-details">
                                        <p><strong>Legal Name: {props.shareData.name}</strong></p>
                                        <p><strong>DOB {moment(props.shareData.dob).format("M/D/YYYY")}</strong></p>
                                        <p><strong>Screened on {moment(props.shareData.screeningDate).format("M/D/YYYY")}</strong></p>
                                    </div>
                                </div>
                                <div className="share-color">
                                    <div className={`share-color-results flex ${props.shareData.status === "NEGATIVE" ? "bg-green" : "bg-red" }`}>
                                        <h1>{props.shareData.status}</h1>
                                    </div>
                                    <div className="share-key mt-20 text-left">
                                        {props.shareData.status === "NEGATIVE" ?
                                            <h4><span className="text-green">Green</span> = no reactive or detected positive results</h4>
                                            :
                                            <h4><span className="text-red">Red</span> = at least one STI is detected or reactive OR results are inconclusive</h4>
                                        }
                                        <h4>Tests were completed for {props.shareData.assays ? props.shareData.assays.join(', ') : ''}</h4>
                                    </div>
                                </div>
                            </div>
                            <button className="btn to-share-page mt-20" type="button" onClick={() => setPage(2)}>Share</button>
                        </>
                    :
                        <>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    <h3 className="back-arrow" onClick={() => setPage(1)}><ArrowBackIosIcon /> Back</h3>
                                    <h2>Who do you want to share it with?</h2>
                                    <h4>The recipient(s) will have access to your results for 24 hours after being sent.</h4>
                                </Modal.Title>
                            </Modal.Header>
                            {recipientList.map((recipient, index) => {
                                return (
                                    <div key={index} className="flex-desktop mt-20">
                                        <input value={recipient.recipientName} onChange={(e) => handleChange(e, index, 'name')} className="share_inputs" type="text" placeholder="Full Name" />
                                        <input value={recipient.contactInfo} onChange={(e) => handleChange(e, index, 'contact')} className="share_inputs" type="text" placeholder="Email or Phone Number" />
                                        {index !== 0 ?
                                            <p onClick={() => handleDelete(index)} >Delete</p>
                                            :
                                            <span className="mr-38"></span>
                                        }
                                    </div>
                                )
                            })}

                            {recipientList.length < 5 ?
                                <button className="btn to-share-page add-recipient mt-20" type="button" onClick={handleAddRecipient}>+ another recipient</button>
                            :
                                <button className="btn to-share-page mt-20 disabled" type="button">+ another Recipient</button>
                            }

                            <div className="share-checkbox-wrap mt-20">
                                <div className="flex">
                                    <input checked={myResultsChecked}
                                           onChange={() => setMyResultsChecked(!myResultsChecked)} type="checkbox"
                                           className="mr-10"/>
                                    <p for="vehicle3"> Your authorization is required. By clicking “Share My Results”
                                        you acknowledge and authorize that you are electronically sharing your tbd
                                        health test results and have read and agree to the <a
                                            href="https://www.tbd.health/pages/results-sharing-authorization">Results
                                            Sharing Authorization.</a></p>
                                </div>
                            </div>
                            {myResultsChecked && canSendResults ? 
                                <button className="btn to-share-page mt-20" type="button" onClick={shareInfo}>Share My Results</button>
                            : 
                                <button className="btn to-share-page disabled mt-20" type="button">Share My Results</button>
                            } 
                        </>
                    }
                </>
                :
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h2>Results</h2>
                                <h4>This is what your recipient(s) will see</h4>
                            </Modal.Title>
                        </Modal.Header>
                        <div className="share-data-wrap flex-desktop">
                            <div className="share-data flex align-items-center">
                                <div className="share-img-upload mr-10">
                                    <img src={profilePic} alt="" width="100" />
                                </div>
                                <div className="share-details">
                                    <p><strong>Legal Name: {props.shareData.name}</strong></p>
                                    <p><strong>DOB {moment(props.shareData.dob).format("M/D/YYYY")}</strong></p>
                                    <p><strong>Screened on {moment(props.shareData.screeningDate).format("M/D/YYYY")}</strong></p>
                                </div>
                            </div>
                            <div className="share-color flex-desktop">
                                <div className={`share-color-results flex mr-10 ${props.shareData.status === "NEGATIVE" ? "bg-green" : "bg-red" }`}>
                                    <p>{props.shareData.status}</p>
                                </div>
                                <div className="share-key">
                                    {props.shareData.status === "NEGATIVE" ?
                                        <p><span className="text-green">Green</span> = no reactive or detected positive results</p>
                                        :
                                        <p><span className="text-red">Red</span> = at least one STI is detected or reactive OR results are inconclusive</p>
                                    }
                                    <p><strong>Tests were completed for:</strong> {props.shareData.assays ? props.shareData.assays.join(', ') : ''}</p>
                                </div>
                            </div>
                        </div>
                        {recipientList.length < 5 ?
                            <button className="btn share-results-btn add-recipient mt-20" type="button" onClick={handleAddRecipient}>+ another Recipient</button>
                        :
                            <button className="btn share-results-btn mt-20 disabled" type="button">+ another Recipient</button>
                        }
                        {recipientList.map((recipient, index) => {
                            return (
                                <div key={index} className="flex-desktop mt-20">
                                    <input value={recipient.recipientName} onChange={(e) => handleChange(e, index, 'name')} className="share_inputs" type="text" placeholder="Full Name" />
                                    <input value={recipient.contactInfo} onChange={(e) => handleChange(e, index, 'contact')} className="share_inputs" type="text" placeholder="Email or Phone Number" />
                                    {index !== 0 ?
                                        <p onClick={() => handleDelete(index)} >Delete</p>
                                        :
                                        <span className="mr-38"></span>
                                    }
                                </div>
                            )
                        })}

                        <div className="share-checkbox-wrap mt-20">
                            <div className="flex">
                                <input checked={myResultsChecked}
                                       onChange={() => setMyResultsChecked(!myResultsChecked)} type="checkbox"
                                       className="mr-10"/>
                                <p for="vehicle3">Your authorization is required. By clicking “Sare My Results” you
                                    acknowledge and authorize that you are electronically sharing your tbd health test
                                    results and have read and agree to the <a
                                        href="https://www.tbd.health/pages/results-sharing-authorization">Results
                                        Sharing Authorization.</a></p>
                            </div>
                        </div>
                        {myResultsChecked && canSendResults ? 
                            <button className="btn share-results-btn mt-20" type="button" onClick={shareInfo}>Share My Results</button>
                        : 
                            <button className="btn share-results-btn disabled mt-20" type="button">Share My Results</button>
                        } 
                    </>
                }
            </div>
            <Snackbar open={snackOpen} onClose={handleSnackClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={handleSnackClose} severity="success">
                Your results have been shared. They will have until {moment().format('hh:mm A')} on {moment().add(1, 'day').format('MM/DD/YY')} to view the results. After that, they will need to request a new link from you to view. If they did not receive your results, please tell them to check their Spam folder or contact us at hello@tbd.health.
                </Alert>
            </Snackbar>
        </Modal>
    )
}

export default ShareModal;
