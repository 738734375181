const removeBodyCSS = () => {
    const bodyCSS = ["body_signup", "body_default", "body_login", "body_inbox", "body_portal", "body_history", "body_settings", "accountPages"];
    const removeClass = (arr) => {
        arr.forEach(ele => {
            document.body.classList.remove(ele);
        });
    };
    removeClass(bodyCSS);
};

export default removeBodyCSS;