import React, { useEffect, useState } from "react";
import ApiService from "../../ApiService";
import { Modal } from "react-bootstrap";
import MaterialTable from 'material-table';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import SyncProblemTwoTone from '@material-ui/icons/SyncProblemTwoTone';
import moment from 'moment';
import './RecipientsModal.css';

const RecipientsModal = props => {
    const [recipientData, setRecipientData] = useState([])
    const [updateInvoke, setUpdateInvoke] = useState(true);

    useEffect(() => {
        if(props.show) {
            async function getSharedUsers() {
                try {
                    const getSharedUsers = await ApiService.getSharedUsers(props.kitId);
                    setRecipientData(getSharedUsers.data)
                } catch(ex) {
                    console.error(ex);
                }
            }
            getSharedUsers();
        }
    }, [props.show, props.customerIdState, props.kitId, updateInvoke])

    const revokeAccess = (recipientUUID) => {
        async function deleteSharedUser() {
            try {
                // eslint-disable-next-line no-unused-vars
                const deleteSharedUser = await ApiService.deleteSharedUser(recipientUUID);
                setUpdateInvoke(!updateInvoke);
            } catch(ex) {
                console.error(ex);
            }
        }
        deleteSharedUser();
    }
   
    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            keyboard={false}
            onClick={e => e.stopPropagation()}
            animation={false}
            size="lg"
            centered
        >   
            <div className="recipient-header-wrap">
                <Modal.Header closeButton>
                        <Modal.Title><h2>Shared Recipients</h2></Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-box-body">
                    <MaterialTable
                        columns={[
                            { title: 'Name', field: 'recipientName' },
                            { 
                                title: 'Date last viewed', field: 'lastViewed', render: rowData => {
                                    if(rowData.lastViewed === null) {
                                        return('Never Viewed')
                                    } else {
                                        return (moment(rowData.lastViewed).format("LLLL"))
                                    }
                                }, 
                            },
                            { title: 'Status', field: 'accessRevoked', render: rowData => {
                                if(moment(moment()).isBefore(rowData.expirationDateTime) && rowData.accessRevoked !== true) {
                                    return 'active'
                                } else if(rowData.accessRevoked) {
                                    return 'Revoked'
                                } else {
                                    return 'Expired'
                                }
                            },  }
                        ]}
                        data={recipientData}
                        title="Shared Recipients"
                        actions={[
                            (rowData) => {
                                if(rowData.expired || rowData.accessRevoked){
                                    return{ 
                                        icon: () => <SyncProblemTwoTone />,
                                        tooltip: 'Access has been ' + (rowData.accessRevoked ? 'revoked': 'expired'),
                                        disabled: true,
                                        onClick: (event, rowData) => revokeAccess(rowData.recipientUUID)
                                    }
                                } else {
                                    return{ 
                                        icon: () => <PersonAddDisabledIcon />,
                                        tooltip: 'Revoke Access',
                                        onClick: (event, rowData) => revokeAccess(rowData.recipientUUID)
                                    }
                                }
                            }
                        ]}
                        options={{
                            filtering: false,
                            headerStyle: { position: 'sticky', top: 0 },
                            search: false,
                            paging: false,
                            emptyRowsWhenPaging: false,
                            actionsColumnIndex: -1,
                            draggable: false,
                            showTitle: false,
                            rowStyle: {
                                fontSize: 20,
                            }
                        }}
                    />
                </Modal.Body>                
            </div>  
        </Modal>
    )
}

export default RecipientsModal;
