import React, { useState, useEffect } from "react";
import { AiOutlineCalendar, AiOutlineClose } from "react-icons/ai";
import { TextField, Select, InputLabel, MenuItem } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";

import ApiService from "../../ApiService";
import MuiAlert from "@material-ui/lab/Alert";

import "./style.css";
import "./check-box.css";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UpdateSubModal = ({ toggleShow, subId }) => {
  const [selectedItems, setSelectedItems] = useState({
    skipNextShipment: true,
    modifySubscription: false,
    cancelSubscription: false
  });
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState("");
  const [snackText, setSnackText] = useState("");

  const [reason, setReason] = useState("");
  const [nextShipmentDate, setNextShipmentDate] = useState("");
  const [subInterval, setSubInterval] = useState("");
  const [subDate, setSubDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const resetState = () => {
    setReason("");
    setNextShipmentDate("");
    setSubInterval("");
    setSubDate("");
    setIsLoading(false);
  };

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const createSubSkipShippingRequest = () => {
    const data = {
      subscriptionId: subId,
      reason: reason,
      nextShipmentDate: nextShipmentDate
    };
    if (!data?.nextShipmentDate) {
      setSnackSeverity("error");
      setSnackText("Please select next shipment date");
      setSnackOpen(true);
      return;
    }
    if (!data?.reason) {
      setSnackSeverity("error");
      setSnackText("Please enter reason");
      setSnackOpen(true);
      return;
    }
    setIsLoading(true);

    ApiService.createSubSkipShippingRequest(data)
      .then(res => {
        setSnackSeverity("success");
        setSnackText("Request sent successfully");
        setSnackOpen(true);
        setIsLoading(false);
        resetState();
      })
      .catch(err => {
        console.error("createSkipShippingRequest", err);
        setSnackSeverity("error");
        setSnackText("Something went wrong");
        setSnackOpen(true);
        setIsLoading(false);
      });
  };
  const createSubModifyRequest = () => {
    const data = {
      subscriptionId: subId,
      reason: reason,
      subscriptionPeriod: subInterval,
      nextShipmentDate: subDate
    };

    if (!data?.subscriptionPeriod) {
      setSnackSeverity("error");
      setSnackText("Please select subscription interval");
      setSnackOpen(true);
      return;
    }
    if (!data?.nextShipmentDate) {
      setSnackSeverity("error");
      setSnackText("Please select shipment date");
      setSnackOpen(true);
      return;
    }
    if (!data?.reason) {
      setSnackSeverity("error");
      setSnackText("Please enter reason");
      setSnackOpen(true);
      return;
    }
    setIsLoading(true);

    ApiService.createSubModifyRequest(data)
      .then(res => {
        setSnackSeverity("success");
        setSnackText("Request sent successfully");
        setSnackOpen(true);
        setIsLoading(false);
        resetState();
      })
      .catch(err => {
        console.error("createSkipShippingRequest", err);
        setSnackSeverity("error");
        setSnackText("Something went wrong");
        setSnackOpen(true);
        setIsLoading(false);
      });
  };
  const createSubCancelRequest = () => {
    const data = {
      subscriptionId: subId,
      reason: reason
    };
    if (!data?.reason) {
      setSnackSeverity("error");
      setSnackText("Please enter reason");
      setSnackOpen(true);
      return;
    }
    setIsLoading(true);

    ApiService.createSubCancelRequest(data)
      .then(res => {
        console.log("createSkipShippingRequest", res);
        setIsLoading(false);
        setSnackSeverity("success");
        setSnackText("Request sent successfully");
        setSnackOpen(true);
        resetState();
      })
      .catch(err => {
        console.log("createSkipShippingRequest", err);
        setIsLoading(false);
        setSnackSeverity("error");
        setSnackText("Something went wrong");
        setSnackOpen(true);
      });
  };

  const navLink = [
    // {
    //   name: "Skip Next Shipment",
    //   value: "skipNextShipment",
    //   checked: selectedItems.skipNextShipment
    // },
    {
      name: "Modify Subscription",
      value: "modifySubscription",
      checked: selectedItems.modifySubscription
    },
    {
      name: "Cancel Subscription",
      value: "cancelSubscription",
      checked: selectedItems.cancelSubscription
    }
  ];

  const handleCheckboxChange = item => {
    // set item true rest false
    let temp = {
      skipNextShipment: false,
      modifySubscription: false,
      cancelSubscription: false
    };
    temp[item] = true;
    setSelectedItems(temp);
  };
  return (
    <div className="updateSubModal">
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackClose} severity={snackSeverity}>
          {snackText}
        </Alert>
      </Snackbar>
      <div className="updateSubModal-content">
        <div className="updateSubModal-header">
          <p className="updateSubModal-title">Modify Subscription Request</p>
          <AiOutlineClose
            size={25}
            color="#EE8155"
            style={{
              cursor: "pointer"
            }}
            onClick={toggleShow}
          />
        </div>
        <hr
          style={{
            color: "#D3D1D8",
            backgroundColor: "#D3D1D8",
            height: 1,
            width: "100%",
            marginTop: 10,
            marginBottom: 10
          }}
        />
        <div className="updateSubModal-body">
          <div className="updateSubModal-navPanel">
            <div className="updateSubModal-navPanel">
              {navLink.map((item, index) => {
                return (
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      checked={item.checked}
                      onChange={() => handleCheckboxChange(item.value)}
                    />
                    <span className="checkbox-custom"></span>
                    {item.name}
                  </label>
                );
              })}
            </div>
          </div>
        </div>
        {selectedItems.skipNextShipment && (
          <div className="skipNextShipment-cont">
            <div className="textInput-cont">
              <span className="text-input-label">Next Shipment Date</span>
              <TextField
                id="date"
                type="date"
                className="text-input"
                inputProps={{
                  style: { fontSize: "14px", textAlign: "left" }
                }}
                onChange={e => {
                  setNextShipmentDate(e.target.value);
                }}
              />
            </div>
            <div className="textInput-cont">
              <span className="text-input-label">Reason</span>
              <TextField
                id="date"
                type="text"
                defaultValue=" "
                className="text-input"
                inputProps={{
                  style: { fontSize: "14px", textAlign: "left" }
                }}
                onChange={e => {
                  setReason(e.target.value);
                }}
              />
            </div>
            <div className="skipNextShipment-btt-cont">
              <button
                className="updateSubModal-cancel-bttn"
                onClick={toggleShow}
              >
                Cancel
              </button>
              <button
                className={`updateSubModal-send-bttn ${isLoading && "loading"}`}
                onClick={createSubSkipShippingRequest}
              >
                {isLoading ? "Loading" : "Send Request"}
              </button>
            </div>
          </div>
        )}

        {selectedItems.modifySubscription && (
          <div className="skipNextShipment-cont">
            <div className="textInput-cont">
              <div className="textInput-cont">
                <span className="text-input-label">Subscription Interval</span>

                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label="sub-interval"
                  onChange={e => {
                    setSubInterval(e.target.value);
                  }}
                >
                  <MenuItem
                    style={{
                      fontSize: "14px",
                      textAlign: "left",
                      color: "#000000"
                    }}
                    value="every-month"
                  >
                    Every Month
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontSize: "14px",
                      textAlign: "left",
                      color: "#000000"
                    }}
                    value="every-3-month"
                  >
                    Every 3 Month
                  </MenuItem>
                  <MenuItem
                    style={{
                      fontSize: "14px",
                      textAlign: "left",
                      color: "#000000"
                    }}
                    value="every-6-month"
                  >
                    Every 6 Month
                  </MenuItem>
                </Select>
              </div>
              <div className="textInput-cont">
                <span className="text-input-label">Shipment Date</span>
                <TextField
                  id="date"
                  type="date"
                  className="text-input"
                  inputProps={{
                    style: { fontSize: "14px", textAlign: "left" }
                  }}
                  onChange={e => {
                    setSubDate(e.target.value);
                  }}
                />
              </div>
              <div className="textInput-cont">
                <span className="text-input-label">Reason</span>
                <TextField
                  id="reason"
                  type="text"
                  defaultValue=" "
                  className="text-input"
                  inputProps={{
                    style: { fontSize: "14px", textAlign: "left" }
                  }}
                  onChange={e => {
                    setReason(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="skipNextShipment-btt-cont">
              <button
                className="updateSubModal-cancel-bttn"
                onClick={toggleShow}
              >
                Cancel
              </button>
              <button
                className={`updateSubModal-send-bttn ${isLoading && "loading"}`}
                onClick={createSubModifyRequest}
              >
                {isLoading ? "Loading" : "Send Request"}
              </button>
            </div>
          </div>
        )}

        {selectedItems.cancelSubscription && (
          <div className="skipNextShipment-cont">
            <div className="textInput-cont">
              <span className="text-input-label">Reason</span>
              <TextField
                id="date"
                type="text"
                defaultValue=" "
                className="text-input"
                inputProps={{
                  style: { fontSize: "14px", textAlign: "left" }
                }}
                onChange={e => {
                  setReason(e.target.value);
                }}
              />
            </div>
            <div className="">
              <span className="helper-text">
                If you are cancelling your subscription before you have received
                and paid for your second Kit, you will be charged the difference
                between the subscription price and the one-time Kit fee.
              </span>
            </div>
            <div className="skipNextShipment-btt-cont">
              <button
                className="updateSubModal-cancel-bttn"
                onClick={toggleShow}
              >
                Cancel
              </button>
              <button
                className={`updateSubModal-send-bttn ${isLoading && "loading"}`}
                onClick={createSubCancelRequest}
              >
                {isLoading ? "Loading" : "Send Request"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Card = ({
  subId,
  kitName,
  subDetail,
  subType,
  subDate,
  nextBillingDate,
  onClick,
  setSelectedSubId
}) => {
  return (
    <div className="card-container">
      <div className="card">
        <div className="left-section">
          <div className="sub-id">{subId}</div>
          <div className="kit-name">{kitName}</div>
        </div>
        <div className="right-section">
          <div className="section-1">
            <div className="sub-detail">Subscription Details</div>
            <div className="sub-type">{subType}</div>
            <div className="sub-date">
              {`Created Date : ${subDate}`}

              {/* <AiOutlineCalendar className="calendar-icon" /> */}
            </div>
            {nextBillingDate && (
              <div className="sub-date">
                {`Next Billing Date : ${nextBillingDate}`}
              </div>
            )}
          </div>
          <div className="section-2">
            <button
              className="modify-button"
              onClick={() => {
                setSelectedSubId(subId);
                onClick();
              }}
            >
              Modify Subscription
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

function formatEpochDate(epochTimestamp) {
  // Create a new Date object by multiplying the epoch timestamp by 1000 (to convert seconds to milliseconds)
  const date = new Date(epochTimestamp * 1000);

  // Get the components of the date (year, month, day, hours, minutes, seconds)
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
  const day = String(date.getDate()).padStart(2, "0");
  // Create a formatted date string in the desired format (e.g., YYYY-MM-DD HH:MM:SS)
  const formattedDate = `${month}-${day}-${year}`;

  return formattedDate;
}

const ManageSubscription = props => {
  const [basicModal, setBasicModal] = useState(false);
  const toggleShow = () => setBasicModal(!basicModal);

  const [subscriptionList, setSubscriptionList] = useState([]);
  const [selectedSubId, setSelectedSubId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    ApiService.getUserSubscriptions()
      .then(res => {
      if(res?.data){
        setSubscriptionList(res?.data || []);
      }        
      })
      .catch(err => {
        console.log("getUserSubscriptions", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      {basicModal && (
        <UpdateSubModal toggleShow={toggleShow} subId={selectedSubId} />
      )}
      {isLoading && (
        <div className="loading-container">
          <div className="loading"></div>
        </div>
      )}
      {subscriptionList?.map((item, index) => {
        return (
          <Card
            subId={item?.processorSubId}
            kitName={item?.productName}
            subDetail={item?.description}
            subType={item?.periodType}
            subDate={formatEpochDate(item?.createdEpoch)}
            onClick={toggleShow}
            setSelectedSubId={setSelectedSubId}
            nextBillingDate={ item?.nextBillingEpoch? formatEpochDate(item?.nextBillingEpoch) : null}
          />
        );
      })}

      {subscriptionList?.length === 0 && !isLoading && (
        <div className="no-subscription">
          <p className="no-subscription-title">No subscription found</p>
        </div>
      )}
    </div>
  );
};

export default ManageSubscription;
