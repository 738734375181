import {Auth} from "aws-amplify";
import React from "react";
import {useHistory} from "react-router-dom";
import url from "../../util/routeURL";

const MyAccountModal = (props) => {
    const history = useHistory();

    const logout = () => {
        Auth.signOut()
            .then(() => {
                localStorage.clear();
                // window.location.replace("/login");
            })
            .catch(err => {
                console.error(err);
            })
    };

    return (
        <>
        {props.openModal &&
            <div className="account" id="my_account">
                <div className="account-content">
                    <div className="home-logout">
                        <span id="modal-link-header" className="account-link home-button">
                            <button className="button-destructure account-link-button" 
                            id="home" 
                            onClick={() => {props.toggleModal(); history.push(url.home)}}>Home
                            </button>
                        </span>
                    </div>
                    <span id="modal-link-header" className="account-link history-link">
                        <button className="button-destructure account-link-button" 
                        id="messages" 
                        onClick={() => {props.toggleModal(); history.push(url.history)}}>History
                        </button>
                    </span>
                    <span id="modal-link-header" className="account-link messages-link">
                        <button className="button-destructure account-link-button" 
                        id="messages" 
                        onClick={() => {props.toggleModal(); history.push(url.inbox)}}>Messages
                        </button>
                    </span>
                    <span id="modal-link-header" className="account-link messages-link">
                        <button className="button-destructure account-link-button" 
                        id="manage-subscription" 
                        onClick={() => {props.toggleModal(); history.push(url.manageSubscription)}}>Manage Subscription
                        </button>
                    </span>
                    <span id="modal-link-header" className="account-link settings rounded-bottom">
                        <button className="button-destructure account-link-button" 
                        id="logout" 
                        onClick={() => logout()}>Logout</button>
                    </span>
                </div>
            </div>
        }
        </>
    )
}

export default MyAccountModal;
