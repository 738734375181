import {Redirect, Route, useHistory, useLocation} from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import React, {Component, useEffect, useState} from "react";
import { Auth } from "@aws-amplify/auth";
import url from "./routeURL";
import ApiService from "../ApiService";
import useUserStore from "../hook/useUserStore";
import CircularProgress from "@material-ui/core/CircularProgress";


const AuthRoute = ({ component: Component, path, exact, ...rest }) => {
    const [loading, setLoading] = useState(true);
    const patient = useUserStore((state) => state.patient);
    const setPatient = useUserStore((state) => state.setPatient);
    const isAuth = useUserStore((state) => state.isAuth);
    const setIsAuth = useUserStore((state) => state.setIsAuth);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        const checkUserSignedIn = async () => {
            try {
                const sess = await Auth.currentSession()
                    .then(sess => {
                        if (isAuth !== true) setIsAuth(true);
                    })
                    .catch(err => {
                        setIsAuth(false);
                    });
            } catch (err) {
                setIsAuth(false)
            }
        };
        checkUserSignedIn().then(r => r);
        const getPatient = async () => {
            await ApiService.getPatientAfterSessRef()
                .then(res => setPatient(res.data))
                .catch(err => setPatient(null))
                .finally(() => setLoading(false));
        }
        getPatient().then(r => r);
    },[path, Component]);

    const isHealthProfComplete = () => {
        return !!patient?.address?.region
            && !!patient?.dob?.trim()
            && !!patient?.gender?.trim()
            && !!patient?.genderAtBirth?.trim();
    }

    const renderFunc = (props, rest) => {
        if (loading){
            return <CircularProgress className="loading-button-spinner"/>;
        }
        if (!isAuth){
            const redirectTo = window.location.pathname + window.location.search;
            const loginUrl = `${url.login}?next=${redirectTo}`;
            return <Redirect to={loginUrl} {...rest} />;
        }
        //send actual component,
        // else it will create circular redirect. i.e: AuthRoute -> healthProfile Redirect -> AuthRoute
        if(path === url.healthProfile){
            return <Component {...props} {...rest} />;
        }
        //profile incomplete, get it filled
        if (!isHealthProfComplete()){
            return <Redirect to={`${url.healthProfile}?next=${path}&missing-info=true`} {...props} {...rest} />;
        }
        return <Component {...props} {...rest} />;
    };
    return (
        <Route
            path={path}
            exact={exact}
            render={props => renderFunc(props, rest)} />
    );
}

export default AuthRoute;
