import React, {useEffect, useState} from 'react';
import Navigation from './Router';
import ReactPixel from 'react-facebook-pixel';
import { Hub, Auth } from 'aws-amplify';
import ApiService from "./ApiService";
import useUserStore from "./hook/useUserStore";

ReactPixel.init('155837033166212');
ReactPixel.pageView();

const App = () => {
    const [loading, setLoading] = useState(false);
    const patient = useUserStore((state) => state.patient);
    const setPatient = useUserStore((state) => state.setPatient);
    const isAuth = useUserStore((state) => state.isAuth);
    const setIsAuth = useUserStore((state) => state.setIsAuth);

    useEffect(() => {
        setLoading(true);
        const checkUserSignedIn = async () => {
            try {
                const sess = await Auth.currentSession()
                    .then(sess => {
                        sess ? setIsAuth(true) : setIsAuth(false)
                    })
                    .catch(err => setIsAuth(false));
            } catch (err) {
                setIsAuth(false)
            }
        };
        checkUserSignedIn().then(r => r);
    },[]);

    useEffect(() => {
        if(isAuth === false){
            setLoading(false);
            return;
        }
        setLoading(true);
        const getPatient = async () => {
            await ApiService.getPatientAfterSessRef()
                .then(res => setPatient(res.data))
                .catch(err => setPatient(null))
                .finally(() => setLoading(false));
        }
        getPatient().then(r => r);
    },[isAuth]);

    Hub.listen('auth', (data) => {
        switch(data.payload.event){
            case 'signIn':
                if(isAuth !== true) setIsAuth(true);
                break;
            case 'signOut':
                setIsAuth(false);
                break;
            default:
                // do nothing
        }
    });
    return (
        <div className="App">
            <Navigation loginStatus={isAuth} />
        </div>
    )
};

export default App;