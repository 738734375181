/* eslint-disable jsx-a11y/anchor-is-valid */
import url from "../../util/routeURL";
import ApiService from "../../ApiService";
import React, { useState, useEffect } from "react";
import ReactTooltip from 'react-tooltip';
import NavAuth from "./NavAuth";
import MenuModal from "./MenuModal";
import { useHistory, useLocation } from "react-router-dom";
import { HomeNav } from "./HomeNav";
import { Menu, Close } from '@material-ui/icons';
import useUserStore from "../../hook/useUserStore";
import nonOperationStates from "../../util/NonOperationStates";
import { Worker } from '@react-pdf-viewer/core';

const TbdHealthHeader = ({loginStatus}) => {
    const [buttonEnable, setButtonEnable] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const patient = useUserStore((state) => state.patient);
    const isAuth = useUserStore((state) => state.isAuth);

    const signupLocation = (location.pathname === '/login' || location.pathname === '/signup' || location.pathname === '/forgotpassword');

    useEffect(() => {
        if(isAuth === false || isAuth === null || isAuth === ""){
            setButtonEnable(false);
            return;
        }
        //non operational states
        if(patient?.address?.region
            && nonOperationStates.indexOf(patient?.address?.region) > -1){
            setButtonEnable(false);
            return;
        }
        setButtonEnable(true);
    },[patient, isAuth]);

    return (
        <div id="shopify-section-header" className="shopify-section">
            <div data-section-id="header" data-section-type="header-section">
                <header className="site-header logo--left" role="banner">
                    <div className="announcement-bar">
                        <h4 className="announcement-bar__message">
                        </h4>
                    </div>
                    <div className="grid grid--no-gutters grid--table site-header__mobile-nav">
                        {
                            signupLocation ? <HomeNav buttonEnable={buttonEnable} />:
                            <>
                                <nav className="grid__item medium-up--one-half small--hide" id="AccessibleNav" role="navigation">
                                    <ul className="site-nav list--inline" id="SiteNav">
                                        <li>
                                            <a href="https://www.tbd.health" className="site-header__logo-image">
                                                <img className="lazyload js bright-img" alt="logo"
                                                    src="/img/logo.svg"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.tbd.health/products"
                                           className="site-nav__link site-nav__link--main">
                                                <span className="site-nav__label">Get a kit</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.tbd.health/about"
                                            className="site-nav__link site-nav__link--main"><span
                                                className="site-nav__label">About</span></a>
                                        </li>

                                        <li>
                                            <NavAuth/>
                                        </li>

                                        <li>
                                            <button id="registerKit-header" className="site-nav__link site-nav__link--main button-destructure transparent-bga" onClick={() => history.push(url.registerkit)}>
                                                <span className="site-nav__label">Register kit</span>
                                            </button>
                                        </li>

                                        <li>
                                            <a href="https://www.tbd.health/learn"
                                            className="site-nav__link site-nav__link--main">
                                                <span className="site-nav__label">UnLearn</span>
                                            </a>
                                        </li>

                                        <li>
                                            <a href="https://www.tbd.health/pages/faqs"
                                            className="site-nav__link site-nav__link--main">
                                                <span className="site-nav__label">FAQ</span>
                                            </a>
                                        </li>
                                        <li>
                                            { !buttonEnable &&
                                                <a data-tip="Login to access settings">
                                                    <button id="registerKit-header" 
                                                        className="site-nav__link site-nav__link--main button-destructure transparent-bga">
                                                        <i className="fas fa-cog"></i>
                                                        <ReactTooltip place="bottom" effect="solid"/>
                                                    </button> 
                                                </a>
                                            }
                                            { buttonEnable &&
                                                <button id="registerKit-header" 
                                                    className="site-nav__link site-nav__link--main button-destructure transparent-bga" 
                                                    onClick={() => history.push(url.setting)}>
                                                    <i className="fas fa-cog"></i>
                                                </button> 
                                            }       
                                        </li>
                                    </ul>
                                </nav>
                            </>
                        }
                        
                        <div className="grid__item text-right site-header__icons mobile-show">
                            <div className="site-header__icons-wrapper">
                                <a href="https://www.tbd.health" className="site-header__logo-image">
                                    <img className="lazyload js bright-img" alt="logo"
                                        src="/img/logo.svg"/>
                                </a>
                                <button type="button" className="btn--link site-header__icon site-header__menu js-mobile-nav-toggle mobile-nav--open mobile-nav--close is button" aria-controls="MobileNav" aria-expanded="false" aria-label="Menu">
                                    {!openMenu ?
                                        <span className="open-menu" id="open-menu" onClick={() => setOpenMenu(true)}><Menu /></span>
                                        :
                                        <span className="close-menu" id="close-menu" onClick={() => setOpenMenu(false)}><Close /></span>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js"></Worker>
                    <MenuModal handleMenuClose={() => setOpenMenu(false)} openMenu={openMenu}/>
                </header>
            </div>
        </div>
    );
};

export default TbdHealthHeader;
