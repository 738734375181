import React, { useState, useRef } from "react";
import ApiService from "../../ApiService";
import { parseAxiosError } from "../../util/parseHelper";
import { useHistory } from "react-router-dom";
import url from "../../util/routeURL";
import { AttachFile } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import "./Message.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NewMessage = () => {
    const history = useHistory();

    const [selectedAttachment, setSelectedAttachment] = useState([]);
    const [attachedFileS3URLs, setAttachedFileS3URLs] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackSeverity, setSnackSeverity] = useState("");
    const [snackText, setSnackText] = useState("");
    const fileInputRef = useRef(null);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            const content = document.getElementById("content").value;
            const subject = document.getElementById("subject").value;
            const msg = {
                subject: subject,
                content: content,
                attachmentUrls: attachedFileS3URLs
            };
            async function postMessage() {
                try {
                    // eslint-disable-next-line no-unused-vars
                    const postMessages = await ApiService.postMessage(msg);
                    window.location.reload();
                    history.push(url.inbox)
                } catch(ex) {
                    console.error(ex);
                }
            }
            postMessage();
        }
    }

    const handleAttachmentClick = () => {
      if (selectedAttachment?.length >= 5) {
        return handleSnackOpen("Only 5 File allowed at a time", "error");
      }
      fileInputRef.current.click();
    };
  
    const handleFileChange = async (event) => {
      const files = Array.from(event.target.files)[0];
      if (!files) {
        return;
      }
      handleSnackOpen("Uploading File", "info");
      try {
        await ApiService.uploadFile(files, 'MESSAGE_ATTACHMENTS').then(resp => {
          const { url } = resp?.data?.body || {};
          files.url = url;
          setSelectedAttachment([...selectedAttachment, files]);
          setAttachedFileS3URLs([...attachedFileS3URLs, url]);
          handleSnackOpen("Upload success", "success");
        });
      } catch (err) {
        if (fileInputRef.current.value) {
          fileInputRef.current.value = null;
        }
        handleSnackOpen(`Upload failed, cause: ${parseAxiosError(err)?.reason ?? "unknown"}`, "error");
      }
    };

    const handleSnackOpen = (text, severity) => {
        setSnackSeverity(severity);
        setSnackText(text);
        setSnackOpen(true);
    };

    const handleSnackClose = () => {
        setSnackOpen(false);
    };

    function formatFileSize(size) {
        if (size < 1024) {
          return `(${size} B)`;
        } else if (size < 1048576) {
          const kbSize = Math.round(size / 1024);
          return `(${kbSize} KB)`;
        } else {
          const mbSize = Math.round(size / 1048576);
          return `(${mbSize} MB)`;
        }
      }
    
      const removeFileFromAttachment = async (index) => {
        const fileName = selectedAttachment[index]?.url;
        await ApiService.deleteFile('MESSAGE_ATTACHMENTS', fileName)
        .then(() => {
          const newAttachment = [...selectedAttachment];
          newAttachment.splice(index, 1);
          setSelectedAttachment(newAttachment);
  
          const s3Urls = [...attachedFileS3URLs];
          const indexOfFileName = s3Urls.indexOf(fileName);
          s3Urls.splice(indexOfFileName, 1);
          setAttachedFileS3URLs(s3Urls);
          handleSnackOpen("Success", "success");
          if (fileInputRef.current.value) {
            fileInputRef.current.value = null;
          }
        })
        .catch(error => {
          handleSnackOpen(parseAxiosError(error)?.reason, "error");
        });
      };

    return (
        <div class="new_msg_container">
            <form class="needs-validation new_msg_form" noValidate
                onSubmit={handleSubmit}>

                <div className="send-overhead">
                    <div className="new-msg-to-container">
                        <p className="new_subject">To:</p>
                        <p className="new_subject">
                            &nbsp;
                            &nbsp;
                            <strong>
                                TBD Care Team
                            </strong>
                        </p>
                    </div>
                    <div class="new_msg_input">
                        {/* <p class="new_subject">Subject:</p> */}
                        <div class="msg_inputs msg_inputs_right">
                            <input placeholder="Subject" type="text" id="subject" name="subject" class="msg_sub_input" />
                        </div>
                    </div>
                </div>
                <div class="inbox_container">
                    <textarea placeholder="Type your message here" name="body" id="content" cols="30" rows="10" class="msg_inputs send_textarea"></textarea>
                </div>
                <div>
                  {selectedAttachment && selectedAttachment.map((item, index) => (
                    <div className="attachment-files">
                      <div className="file-name-text">
                        {item.name}
                      </div>
                      <div style={{ margin: "0px 10px" }}>{formatFileSize(item.size)}</div>
                      <div
                        onClick={() => {
                          removeFileFromAttachment(index);
                        }}
                      >
                        <i
                          class="fa fa-times"
                          aria-hidden="true"
                          style={{
                            margin: "0px 10px",
                            cursor: "pointer"
                          }}
                        ></i>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="send-message">
                  <button id="msg_reply" class="replyButton sendButton">Send</button>
                  <div>
                    <div
                      onClick={handleAttachmentClick}
                      className="cursor-pointer"
                    >
                      <AttachFile style={{ color: "black", marginLeft: "10px", fontSize: "2.5rem" }} />
                    </div>
                    <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} multiple />
                  </div>
                </div>
                <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                    <Alert onClose={handleSnackClose} severity={snackSeverity}>
                        {snackText}
                    </Alert>
                </Snackbar>
            </form>
        </div>
    )
};
export default NewMessage;
