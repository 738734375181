export const parseAxiosError = (err) => {
    let message = 'Internal server error';
    let reason = 'unknown cause';
    let code = 'NA';
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (err?.response) {
        message = err?.response?.data?.message || message;
        reason = err?.response?.data?.reason || reason;
        code = err?.response?.status || code;
    }
        // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest
    else if (err?.request) {
        reason = 'Please try again. Report to customer service if issue continues';
    }
    // Something happened in setting up the request that triggered an Error
    else {
        reason = 'Please try again. Report to customer service if issue continues';
    }
    return {
        message,
        reason,
        code
    };
};
