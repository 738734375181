import React, {useState, useEffect} from "react";
import moment from 'moment';
import TextTruncate from 'react-text-truncate';
import Filedownload from 'js-file-download';
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import ReplyMessage from "./ReplyMessage";
import MessageReply from "./MessageReply";
import linkedText from "../../util/linked-text";
import ApiService from "../../ApiService";
import "./Message.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MessageText = (props) => {
    const { messageUUID, message, sentMessage } = props;
    const parentUUID = message.messageParentUUID;
    const [replyMessageComponent, setReplyMessageComponent] = useState(null);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackSeverity, setSnackSeverity] = useState("");
    const [snackText, setSnackText] = useState("");

    const handleSnackOpen = (text, severity) => {
        setSnackSeverity(severity);
        setSnackText(text);
        setSnackOpen(true);
    };

    const handleSnackClose = () => {
        setSnackOpen(false);
    };

    function reply() {
        setReplyMessageComponent(<ReplyMessage messageUUID={messageUUID} />);
    }

    useEffect(() => {
        if (!message.fromId && document.getElementById("msg_reply")) {
            document.getElementById("msg_reply").style.display = "none";
        } else if (document.getElementById("msg_reply")) {
            document.getElementById("msg_reply").style.display = "block";
        }
    }, [message.fromId])

    const download = async (fileName) => {
        if (!fileName) {
            return;
        }
        await ApiService.downloadFile('MESSAGE_ATTACHMENTS', fileName)
            .then(resp => {
                Filedownload(resp.data, fileName.substring(fileName.indexOf('_') + 1));
            })
            .catch(async (error) => {
                const data = error?.response?.data;
                if (
                    error?.request?.responseType === 'blob' &&
                    data instanceof Blob &&
                    data?.type &&
                    data.type.toLowerCase().indexOf('json') != -1
                ) {
                    const errorString = JSON.parse(await data?.text());
                    handleSnackOpen(errorString?.reason, "error");
                }
            });
    };

    if (!message) {
        return (<div className="open_message load_message"> <i class="fas fa-spinner fa-pulse"></i></div>)
    } else {
        const formattedText = linkedText(message.content)
        return (
            <div className="open_message chain-start">
                <div className="message_container" id="open_message">
                    {replyMessageComponent}
                    <div className="message_header">
                        <div className="from_header_el header-from">
                            <TextTruncate
                                line={1}
                                element="div"
                                truncateText="…"
                                text={message.from}
                            />
                        </div>
                        <div className="header-subject">
                            <TextTruncate
                                line={1}
                                element="div"
                                truncateText="…"
                                text={message.subject}
                            />
                        </div>
                        <div className="header-date">
                            <TextTruncate
                                line={1}
                                element="div"
                                truncateText="…"
                                text={moment(message.createdDateTime).format("ddd MMM D, YYYY LT")}
                            />
                        </div>
                        {
                            sentMessage !== true && message.fromID !== null ?
                                <button onClick={reply} id="msg_reply" className="replyButton">Reply <i className="fas fa-reply"></i></button>
                                : null
                        }
                    </div>
                </div>
                
                <div id="" className="message-margin root-message">
                    <div dangerouslySetInnerHTML={{ __html: formattedText}} className="message_text">
                        {/* {message.content} */}
                    </div>
                    {message?.attachmentUrls?.length > 0 ? (
                        <div>
                            {message.attachmentUrls.map((item) => (
                                <div className="attachment-files attachment-files-message-text" onClick={() => download(item)}>
                                    <div className="file-name-text">
                                        {item?.substring(item?.indexOf('_') + 1)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}

                    <div id="reply_chain" className="msg_reply">
                        < MessageReply messageUUID={parentUUID} />
                    </div>
                </div>
                <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                    <Alert onClose={handleSnackClose} severity={snackSeverity}>
                        {snackText}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default MessageText;
