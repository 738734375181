import {get, pick} from 'lodash'
import moment from 'moment'

export const getUserDetails = (user) => {
    const address = JSON.parse(get(user, 'attributes.address'))
    return {
        firstName: `${get(user, 'attributes.given_name')}`,
        lastName: `${get(user, 'attributes.family_name')}`,
        email: `${get(user, 'attributes.email')}`,
        phoneNum: `${get(user, 'attributes.phone_number')}`,
        gender: `${get(user, 'attributes.gender')}`,
        birthdate: `${get(user, 'attributes.birthdate')}`,
        allergies: `${get(user, 'attributes.custom:allergies')}`,
        address
    }
}

export const getUserAttributes = (details) => {
    const dob = moment(details.birthdate).format('YYYY-MM-DD')
    return {
        'given_name': details.firstName,
        'family_name': details.lastName,
        'name': `${details.firstName} ${details.lastName}`,
        'email': details.email,
        'gender': details.gender,
        'birthdate': dob,
        'custom:allergies': details.allergies
    }
}

export const getMinimalUserAttributes = (details) => {
    return {
        'email': details.email
    }
}

export const getUserAddress = (address) => {
    const fields = ['locality', 'region', 'postal_code', 'street_address']
    return {
        'address': JSON.stringify(pick(address, fields)),
        'phone_number': pick(address, 'phoneNum').phoneNum
    }
}
