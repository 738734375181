import React, { useState, useEffect } from "react";
import moment from 'moment';
import TextTruncate from 'react-text-truncate';
import ApiService from "../../ApiService";
import MessageDetail from "./MessageDetail";
import useUserStore from "../../hook/useUserStore";
import {useHistory, useLocation} from "react-router-dom";
import {isValidValue} from "../../util/common";
const Message = () => {
    const [messages, setmessages] = useState(null);
    const [loading, setLoading] = useState(true);
    const [mode, setMode] = useState(null);
    const [messageUUID, setMessageUUID] = useState(null);

    const patient = useUserStore((state) => state.patient);
    const isAuth = useUserStore((state) => state.isAuth);

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(location?.search);
        const msgId = urlSearchParams.get('msgId');
        if(isValidValue(msgId)) {
            (async function () {
                setLoading(true);
                try {
                    await fetchMessage(msgId);
                    setLoading(false);
                    // Remove the msgId parameter from the URL
                    urlSearchParams.delete('msgId');
                    // Create a new URL with the updated search parameters
                    const newURL = `${location.pathname}?${urlSearchParams.toString()}`;

                    // Replace the current URL with the new one
                    history.replace(newURL);
                } catch(ex) {
                    console.error(ex);
                    setLoading(false);
                }
            })();
            return;
        };

        async function getMessages() {
            try {
                const getMessages = await ApiService.getMessages();
                setmessages(getMessages.data);
            } catch(ex) {
                console.error(ex);
            }
            setLoading(false);
        }
        getMessages();
    }, []);
    
    function fetchMessage(messageUUID) {
        setMode('VIEW_MESSAGE_DETAIL');
        setMessageUUID(messageUUID);
    };

    function renderTableData() {
        if (messages) {
            return messages.map((result, index) => {
                const { messageUUID, subject, from, createdDateTime } = result;
                if (messages[index].unread) {
                    return (
                        <tr className="unread-msgs" key={messageUUID} onClick={() => fetchMessage(messageUUID)}>
                            <td key={from}>
                                <TextTruncate
                                line={1}
                                element="span"
                                truncateText="…"
                                text={from}
                                />
                            </td>
                            <td key={subject}>
                                <TextTruncate
                                line={1}
                                element="span"
                                truncateText="…"
                                text={subject}
                                />
                            </td>
                            <td key={createdDateTime}>
                                <TextTruncate
                                    line={1}
                                    element="span"
                                    truncateText="…"
                                    text={moment(createdDateTime).format("ddd MMM D, YYYY LT")}
                                />
                            </td>
                        </tr>
                    )
                } else {
                    return (
                        <tr key={messageUUID} onClick={() => fetchMessage(messageUUID)}>
                            <td key={from}>
                                <TextTruncate
                                    line={1}
                                    element="span"
                                    truncateText="…"
                                    text={from}
                                />
                            </td>
                            <td key={subject}>
                                <TextTruncate
                                    line={1}
                                    element="span"
                                    truncateText="…"
                                    text={subject}
                                />
                            </td>
                            <td key={createdDateTime}>
                                <TextTruncate
                                    line={1}
                                    element="span"
                                    truncateText="…"
                                    text={moment(createdDateTime).format("ddd MMM D, YYYY LT")}
                                />
                            </td>
                        </tr>
                    )
                }
            })
        }
    };

    if (loading) {
        return (
            <div class="load_message">
                <p class="load_text">
                    <i class="fas fa-spinner fa-pulse"></i>
                </p>
            </div>
        );
    } else if (!loading && mode !== 'VIEW_MESSAGE_DETAIL') {
        return (
            <div className="flex-column flex">
                <table class="message_index">
                    <thead>
                        <th class="message_index_sender table_row">Sender</th>
                        <th class="message_index_subject table_row">Subject</th>
                        <th class="message_index_date table_row">Date/Time</th>
                    </thead>
                    {renderTableData()}
                </table>
            </div>
        )
    } else if (mode === 'VIEW_MESSAGE_DETAIL') {
        return (
            <MessageDetail messageUUID={messageUUID} />
        )
    }
};

export default Message;
