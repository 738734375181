
import ReactTooltip from 'react-tooltip';
import { useHistory } from "react-router-dom";
import url from "../../util/routeURL";

export const HomeNav = ({buttonEnable}) => {
    const history = useHistory();
    return (
        <nav className="grid__item medium-up--one-half small--hide" id="AccessibleNav" role="navigation">
            <ul className="site-nav list--inline site-nav_logo" id="SiteNav">
                <li style={{ flex: 2 }}>
                    <a href="https://www.tbd.health" className="site-header__logo-image">
                        <img className="lazyload js bright-img" alt="logo"
                            src="/img/logo.svg"/>
                    </a>
                </li>
                <li style={{ flex: 0.5 , textAlign: "center" }}>
                    <a href="https://www.tbd.health/clinic/vegas"
                    className="site-nav__link site-nav__link--main">
                        <span className="site-nav__label">Clinic</span>
                    </a>
                </li>
                <li style={{ flex: 0.5 , textAlign: "center" }}>
                    <a href="https://www.tbd.health/products"
                    className="site-nav__link site-nav__link--main">
                        <span className="site-nav__label">At-Home</span>
                    </a>
                </li>
                <li style={{ flex: 0.5 , textAlign: "center" }}>
                    <a href="https://www.tbd.health/about"
                    className="site-nav__link site-nav__link--main"><span
                        className="site-nav__label">About</span></a>
                </li>
                <li style={{ flex: 0.5 , textAlign: "center" }}>
                    <a href="https://www.tbd.health/learn"
                       className="site-nav__link site-nav__link--main">
                        <span className="site-nav__label">LEARN</span>
                    </a>
                </li>
                <li className="site-nav_settings">
                <a class="site-nav_login" href="/login">Log in</a>
                    { !buttonEnable &&
                        <a data-tip="Login to access settings">
                            <button id="registerKit-header" 
                                className="site-nav__link site-nav__link--main button-destructure transparent-bga">
                                <i className="fas fa-cog"></i>
                                <ReactTooltip place="bottom" effect="solid"/>
                            </button> 
                        </a>
                    }
                    { buttonEnable &&
                        <button id="registerKit-header" 
                            className="site-nav__link site-nav__link--main button-destructure transparent-bga" 
                            onClick={() => history.push(url.setting)}>
                            <i className="fas fa-cog"></i>
                        </button> 
                    }       
                </li>
            </ul>
        </nav>
    );
}