const SaveConfirmation = (props) => {
    return (
        <div className="closeNotification">
            {props.msg}
            <button onClick={props.onClose}>X</button>
        </div>
    )
}
export default SaveConfirmation;

