import React from "react";
import {get, isEqual} from "lodash";
import * as yup from 'yup';
import {Input} from "reactstrap";
import {Formik} from 'formik';
import SaveConfirmation from "./SaveConfirmation";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import './style.css';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const InitState = {
    currPassoword: '',
    newPassoword: '',
    confirmPassoword: ''
}

class ChangePassword extends React.Component {
    notificatonmsg = ''
    schema = yup.object().shape({
            currPassoword: yup.string()
                .required('Current password is requred'),
            newPassoword: yup.string()
                .required('New password is requred')
                .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character [@, $, !, %, *, #, ?, &]'),
            confirmPassoword: yup.string()
                .oneOf([yup.ref('newPassoword'), null], "New Password and Confirm password don't match!")
                .required('Confirm password is requred')
        }
    );

    intstate = InitState
    errors = {}

    constructor(props) {
        super(props)
        this.state = {
            ...InitState,
            showNotification: false,
            modalOpen: false,
            loading: false
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleNotificationClose = this.handleNotificationClose.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSnackClose = this.handleSnackClose.bind(this);
    }

    handleSnackClose() {
        this.setState({
            modalOpen: false
        });
        window.location.reload();
    };

    handleFormChange(formchange) {
        this.props.onChange(formchange);
    }

    handleNotificationClose() {
        this.setState({showNotification: false})
    }

    async handleSubmit(values) {
        this.setState({loading: true});
        const result = await this.props.onSave(values)
        this.setState({loading: false});
        const {status, message} = result
        this.notificatonmsg = message
        if (status === 'Failure') {
            this.setState({showNotification: true})
        } else if (result === 'SUCCESS') {
            this.setState({modalOpen: true})
        }
    }

    handleChange(formchange) {
        this.props.onChange(formchange);
    }

    render() {
        const renderNotification = () => {
            if (!this.state.showNotification) {
                return ('')
            }
            return (
                <SaveConfirmation msg={this.notificatonmsg} onClose={this.handleNotificationClose}/>
            )
        }
        return (
            <>
                {renderNotification()}
                <Formik
                    initialValues={InitState}
                    onSubmit={this.handleSubmit}
                    validate={this.onSubmit}
                    validateOnChange={false}
                    onChange={this.handleChange}
                    validationSchema={this.schema}
                >
                    {({
                          handleChange, touched, errors,
                          handleBlur, handleSubmit, values
                      }) => {

                        const equal = (key) => {
                            return isEqual(get(values, key), get(InitState, key))
                        }

                        const formSame = () => {
                            return equal('currPassoword') && equal('newPassoword') && equal('confirmPassoword')
                        }

                        const submitDisabled = formSame()
                        this.handleChange(!submitDisabled);

                        return (
                            <form className="detailsForm password" method="post" onSubmit={this.handleSubmit}>
                                <div className="halfArea">
                                    <div className="groupRow">
                                        <label>Current Password</label>
                                        <Input
                                            name='currPassoword'
                                            type='password'
                                            value={values.currPassoword}
                                            onChange={handleChange('currPassoword')}
                                            placeholder="Current Password"/>
                                        {errors.currPassoword && (
                                            <div className="validationText">{errors.currPassoword}</div>)}
                                    </div>
                                    <div className="groupRow">
                                        <label>New Password</label>
                                        <Input
                                            name='newPassoword'
                                            type='password'
                                            onChange={handleChange('newPassoword')}
                                            value={values.newPassoword}
                                            placeholder="New Password"/>
                                        {errors.newPassoword && (
                                            <div className="validationText">{errors.newPassoword}</div>)}
                                    </div>
                                    <div className="groupRow">
                                        <label>Confirm new password</label>
                                        <Input
                                            name='confirmPassoword'
                                            type='password'
                                            onChange={handleChange('confirmPassoword')}
                                            value={values.confirmPassoword}
                                            placeholder="Confirm Password"/>
                                        {errors.confirmPassoword && (
                                            <div className="validationText">{errors.confirmPassoword}</div>)}
                                    </div>
                                    <div className="halfArea">
                                        <div className="groupRow">
                                            <input type="submit" onClick={handleSubmit} disabled={submitDisabled || this.state.loading}
                                                   value="Save"/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )
                    }}
                </Formik>
                <Snackbar autoHideDuration={6000} open={this.state.modalOpen} onClose={this.handleSnackClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert onClose={this.handleSnackClose} severity="success">
                    You're new password has been set!
                    </Alert>
                </Snackbar>
            </>
        )
    }
}

export default ChangePassword
